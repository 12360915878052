@import '~@beewise/theme/build/variables';
@import 'src/variables';

.grid-container {
  position: relative;
  height: 100%;
  width: calc(100vw - (#{$dashboard-padding} * 2) - (#{$dashboard-grid-padding} * 2));
  overflow-x: auto;

  .btn-export {
    position: absolute;
    right: 24px;
    top: 0;

    .button-text {
      display: flex;
      gap: 4px;
      align-items: center;
    }
  }
}

.dashboard-grid {
  padding: $dashboard-grid-padding;
  background-color: $white;
  border-radius: 8px;
  box-shadow: 0 4px 17px 0 rgba(0, 0, 0, .05);

  .searchable-grid-container .grid-wrapper {
    padding: 0;
    margin-top: 16px;
    background-color: unset;
  }
  .MuiTablePagination-actions {
    display: flex;
  }
  .MuiDataGrid-virtualScroller {
    background: $white;
  }
  .MuiDataGrid-columnHeaders {
    background-color: $grey-8;
  }
  .grid-id-cell {
    cursor: pointer;
  }
}

.feed-grid-actions {
  display: flex;
  justify-content: space-between;
  .select-field__control {
    min-width: 148px;
  }
  .btn {
    height: 100%;

    .button-text {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
}

.custom-tab {
  display: flex;
  flex-direction: column;
  height: 100%;
  .tab-content {
    height: 85%;
    padding: 8px 20px;
  }
}
.feed-button-tooltip {
  text-align: left;
  span {
    font-weight: 600;
  }
}
