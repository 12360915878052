@import '~@beewise/theme/build/variables';

.map-controls {
  position: absolute;
  z-index: 1;
  bottom: 36px;
  right: 32px;
  display: flex;
  flex-direction: column;

  .btn {
    background: $white;
    width: 24px;
    height: 24px;
    position: relative;
    font-size: 24px;
    border-radius: 0;

    .button-text {
      display: flex;
      font-size: 16px;
      align-items: center;
      justify-content: center;
    }
  }

  .zoom {
    &-in {
     border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      border-bottom: 1px solid $grey-1;
    }
    &-out {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  &-btn {
    border-radius: 4px;
    background-color: $white;
    font-size: 20px;
    padding: 2px 0;
    width: 24px;
    box-sizing: border-box;
    cursor: pointer;
    margin-bottom: 12px;
    svg {
      width: 12px;
      height: 12px;

      path {
        stroke: $grey-1;
      }
    }

    &.add-location {
      &-inverted {
        background-color: $primary-orange-active;

        path {
          fill: $white;
        }
      }
    }

  }
}
