@import '~@beewise/theme/build/variables';

/* To make @beewise/modal visible on top of the @beewise/menu from StationAssessment */ 
.assessment-modal {
    z-index: $z-index-over-all + 4!important;

    .buttons {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 8px;
    }
}