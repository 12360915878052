@import '~@beewise/theme/build/variables';

.alerts-list {
    box-shadow: 0 30px 60px $gray-03;
    right: 40px;
    border-radius: 4px;

    &-general {
        margin-top: 12px;
        position: absolute;
        z-index: 1000;
        transition: opacity 0.3s ease-in-out;

        &:before {
            content: '';
            position: absolute;
            top: -6px;
            right: 26px;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-bottom: 6px solid $grey-8;
        }
    }

    .list-header {
        background-color: $grey-7;
        padding: 8px 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $black;
        border-radius: 4px 4px 0 0;

        &-title {
            font-size: 12px;
            letter-spacing: 1px;
            text-transform: uppercase;
            line-height: 16px;
            font-weight: 700;
        }

        .icon {
            cursor: pointer;
        }
    }

    .list-content {
        overflow-y: auto;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        background-color: $white;

        &-large {
            max-height: 585px;
        }

        &-medium {
            max-height: 385px;
        }

        &-small {
            max-height: 285px;
        }
    }

    .list-item {
        padding: 8px 8px;
        background-color: $white;
        border-bottom: 1px solid $grey-8;
        cursor: pointer;
        line-height: 12px;
        font-family: Poppins, 'serif';
        font-size: 12px;
        color: $grey-1;
        display: flex;
        justify-content: space-between;
        position: relative;
        box-sizing: border-box;

        &-time {
            display: flex;
            align-items: center;

            .icon {
                color: $grey-3;
                padding-right: 6px;
                svg {
                    width: 14px;
                    height: 14px;
                }
            }

            &-label {
                color: $grey-3;
                font-size: 12px;
                line-height: 16px;
                font-weight: normal;
            }
        }

        &-text {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
        }

        &-source {
            font-family: Poppins;
            color: $link;
            font-weight: normal;
            padding: 2px 0;
            line-height: 16px;
            font-size: 12px;
        }
        &-description {
            padding: 2px 0;
            line-height: 16px;
            font-size: 12px;
        }

        .envelope {
            color: $grey-3;
            visibility: hidden;
            pointer-events: none;
            padding: 4px;
            width: 16px;
            height: 16px;

            &:hover {
                background-color: $grey-8;
                border-radius: 4px;
            }
        }

        .title {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: bold;
            padding: 4px 0;
            line-height: 18px;
            font-size: 14px;

            &-alert-icon {
                color: $bad;
                margin: 0 4px;
            }

            &-info-icon {
                color: $link;
                margin: 0 4px;
            }

            &-warning-icon {
                color: $brand;
                margin: 0 4px;
            }

            &-light-icon {
                color: $brand;
                margin-right: 2px;
            }
        }

        &:hover {
            background-color: $grey-8;
            .envelope {
                visibility: visible;
                pointer-events: initial;
            }
        }

        &-unread {
            .header {
                font-weight: 700;
            }
        }
    }

    .list-item:first-of-type {
        padding: 8px 8px 8px;
    }
}

.tooltip-alert {
    &.ui.bottom.popup {
        z-index: 100000;

        &:before {
            background: $grey-7;
        }
    }
}

.notifications-list-tooltip {
    z-index: 10000;
}
