@import '~@beewise/theme/build/variables';

.right-menu-block.activities {
    padding-top: 24px;

    .right-menu-block-body {
        padding: 0 16px;

        .ui.secondary.pointing.menu {
            min-height: unset;

            a.item {
                font-size: 14px;
                padding: 4px 16px 12px;
            }

            a.active.item {
                color: $kona-grey;
                border-bottom-width: 2px;
                border-bottom-color: $beewise-yellow;
            }
        }
    }
}

.activity-list-item {
    background-color: $gray-01;
    border-radius: 12px;
    padding: 8px;
    margin-bottom: 8px;

    .new-pane-alert-text {
        padding: 0 0 8px;
    }

    &-icon.svg-inline--fa {
        width: 16px;
        height: 16px;
        color: $gray-08;

        &.failed {
            color: $beewise-yellow;
        }

        &.alert {
            color: $red;
        }
    }

    &-header {
        display: flex;
        margin-bottom: 4px;
        line-height: 18px;
        height: 20px;
    }

    &-title {
        font-size: 14px;
        color: $gray;
        margin-left: 8px;
    }

    &-initiator {
        margin-left: auto;
        font-size: 12px;
        color: $gray-06;
    }

    &-discard {
        font-size: 18px;
        color: $gray-08;
        cursor: pointer;
        margin-left: 12px;
        margin-right: 4px;
        display: none;

        &:hover {
            padding: 4px;
            margin-top: -4px;
            background-color: $gray-02;
            border-radius: 4px;
            margin-right: 0;
            margin-left: 8px;
        }
    }

    &-details {
        font-size: 12px;
        color: $gray-06;
        line-height: 14px;
        display: flex;

        &-clock {
            font-size: 12px;
            margin-right: 10px;
            margin-left: 2px;
        }

        &-link {
            color: $blue;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &:first-of-type {
        margin-top: 8px;
    }

    &:hover {
        .activity-list-item-discard {
            display: block;
        }
    }
}

.current-activity {
    border: 1px solid $yellow-dark;
    border-radius: 12px;
    margin: 0 16px 12px 16px;
    padding: 8px;

    &-header {
        display: flex;
        line-height: 18px;

        &-icon {
            width: 16px !important;
            height: 16px;
            color: $gray-09;
        }

        &-title {
            color: $gray-09;
            font-size: 14px;
            margin-left: 8px;
        }

        &-initiator {
            margin-left: auto;
            color: $gray-06;
            font-size: 12px;
            margin-right: 8px;
        }

        &-spinner {
            font-size: 18px;
            color: $gray-09;
            animation: rotate 1.5s linear infinite;
        }
    }

    &-body {
        margin-top: 8px;
        font-size: 12px;
        color: $yellow-dark;
        line-height: 16px;
        margin-left: 24px;
    }

    &-footer {
        display: flex;
        margin-top: 8px;
        line-height: 16px;
        font-size: 12px;
        color: $blue;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }

        &-icon {
            font-size: 12px;
            color: $gray-06;
            margin-left: 2px;
        }

        &-source {
            margin-left: 10px;
            margin-right: 8px;
        }

        &-time-ago {
            margin-left: 8px;
        }
    }

    @keyframes rotate {
        to {
            transform: rotate(360deg);
        }
    }
}
