@import '~@beewise/theme/build/variables';
.action-icon {
    display: flex;
    align-items: center;
    font-weight: 400;
    gap: 4px;
    padding: 4px 8px;
    color: $static-partition;
    border-radius: 4px;
    font-size: 14px;
    line-height: 18px;
    cursor: pointer;
    transition: all 0.1s ease-out;

    &.disabled {
        color: $grey-5;
        cursor: default;

        &:hover {
            background-color: transparent;
        }
    }

    &:hover,
    &.active {
        background-color: $secondary-blue;
    }

    svg {
        width: 16px;
        height: 16px;
    }
}
