@import '~@beewise/theme/build/variables';

$checkbox-size: 10px;
$icon-size: 10px;

$checkbox-size-small-screen: 8px;
$icon-size-small-screen: 8px;

$frame-min-width: 8px;

.station {
    background-color: $white;
    box-sizing: border-box;
    max-height: 164px;
    height: 100%;
    min-height: 164px;
    display: flex;
    flex-direction: column;
    position: relative;
    min-width: 100%;

    .station-hive-radio {
        position: absolute;
        top: 15px;
        display: flex;
        align-items: center;

        input {
            padding: 2px;
        }

        label {
            padding-left: 4px;
        }
    }

    &-content {
        display: flex;
        flex: 1;

        .balance-checkbox {
            position: absolute;
            top: -15px;
            accent-color: $beewise-yellow;

            .checkbox-container {
                padding: 0;
            }

            .checkbox-input {
                width: 8px;
                height: 8px;
            }
        }
    }

    &-frame {
        min-width: $frame-min-width;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: $gray-30;
        height: 100%;
        cursor: pointer;

        &-wrapper {
            position: absolute;
            top: 0;
            height: 100%;
            margin: 0 1px 0 0;

            &-outside {
                opacity: .3;
            }

            &-static-partition {
                .station-frame {
                    min-width: calc($frame-min-width / 2);
                }
            }

            &-inspected {
                border: 1px solid $primary-blue;
                box-sizing: border-box;
            }

            .checkbox-container {
                padding: 0;
            }

            .checkbox-input {
                width: $checkbox-size;
                height: $checkbox-size;
                margin: 2px 0;
            }

            .station-frame-exclamation-square {
                width: $checkbox-size;
                height: $checkbox-size;
                color: $red;
                cursor: pointer;
            }

            .station-frame-alert-icon {
                font-size: 12px;
                color: #cd1c00;
                pointer-events: none;
            }

            .station-frame-status-icon {
                font-size: 12px;
                color: $gray-04;

                &.with-checkbox {
                    top: -44px;
                }

                &.alerted {
                    path {
                        fill: $bad;
                    }
                }

                &.alerted+.station-frame-status-icon {
                    margin-top: 2px;
                }
            }

            .frame-arrow {
                position: absolute;
                color: $gray-04;
                bottom: -17px;
                left: -1px;
                background: white;
                z-index: 1;
                border: 1px solid $gray-04;
                width: $checkbox-size;
                height: $checkbox-size;
                transform: rotate(45deg);
                border-bottom: 0;
                border-right: 0;
            }
        }

        &-empty {
            cursor: default;
        }

        @import 'src/components/reusables/Hive/FrameColors';
    }

    &-hive {
        height: 10px;
        min-width: 8px;
        position: absolute;
        border-radius: 0 0 10px 10px !important;
        border: none;
        padding: 0;
    }

    &-hives-wrapper {
        position: relative;
        bottom: -4px;
        display: flex;
        width: 100%;

        .btn-gradient {
            background: linear-gradient(180deg,
                    rgba(255, 255, 255, 1) 44%,
                    rgba(235, 235, 245, 1) 61%,
                    rgba(196, 196, 196, 1) 100%);
        }

        .btn {
            padding: 0;
        }
    }

    .station-frame-item-wrapper {
        width: 100%;
        position: relative;

        &-status {
            position: absolute;
            display: flex;
            flex-direction: column;
            transform: translateY(-100%);
            align-items: center;
            left: -1px;
            top: -4px;

            .fa-droplet {
                margin-left: 1px;
            }
        }
    }

    .station-frame-status-icon {
        font-size: $icon-size;
        color: $gray-04;

        &.with-checkbox {
            top: -44px;
        }

        &.alerted {
            path {
                fill: $bad;
            }
        }

        &.alerted+.hive-block-frame-status-icon {
            margin-top: 2px;
        }
    }
}

.station-assessment-tooltip {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 14px;
    font-weight: 500;
    padding: 8px 12px;
    gap: 10px;

    &-title {
        font-size: 18px;
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: baseline;
    }

    &-icon {
        color: $text-secondary;

        &-success {
            color: $open-brood;
        }

        &-failed {
            color: $red;
        }
    }
}

/* TODO: return on left panel remove */
/* remove with left panel */
@media screen and (max-width: 1620px) {
    .station {
        &-frame {
            &-wrapper {
                .checkbox-input {
                    width: $checkbox-size-small-screen;
                    height: $checkbox-size-small-screen;
                    margin: 2px 0;
                }

                .station-frame-exclamation-square {
                    width: $checkbox-size-small-screen;
                    height: $checkbox-size-small-screen;
                }
            }
        }

        .station-frame-status-icon {
            font-size: $icon-size-small-screen;
        }

        .station-frame-item-wrapper-status {
            left: 0;
        }
    }
}

/* To make @beewise/menu visible on top of the tooltip */
#menu-root {
    .menu {
        z-index: $z-index-over-all + 2 !important;
    }
}

.station-assessment-tooltip-content {
    background-color: $white !important;
    color: $text-primary !important;
    box-shadow: 0px 3px 14px 2px rgba(0, 0, 0, .12), 0px 8px 10px 1px rgba(0, 0, 0, .14), 0px 5px 5px -3px rgba(0, 0, 0, .2);
}