@import '~@beewise/theme/build/variables';

.full-screen-image-modal-bottom-block {
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  width: fit-content;

  &.hidden {
      opacity: 0;
  }
}