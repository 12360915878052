@import '~@beewise/theme/build/variables';

.name-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    height: fit-content;

    &-name {
      color: $black;
    }

    &-title {
      color: $grey-3;
    }
}
