@import '~@beewise/theme/build/variables';

.v1-search-box {
    margin: 0;
    max-width: 188px;
    width: 100%;
    background-color: $light-grey;
    height: 28px;
    border-radius: 8px;
    padding: 4px;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    &-input {
        border: unset;
        width: 100%;
        height: 100%;
        background-color: transparent;
        margin-left: 4px;
        font-size: 12px;
        color: $grey-3;

        &::placeholder {
            font-size: 12px;
            color: $grey-4;
        }

        &:focus {
            outline: none;
        }
    }

    &-icon {
        margin-left: 4px;
        color: $grey-4;
    }

    &-clear {
        margin-left: 4px;
        color: $grey-4;
        cursor: pointer;
    }
}

.pac-container.pac-logo.hdpi {
    width: 300px !important;
}
