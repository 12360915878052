@import '~@beewise/theme/build/variables';

.empty-location {
    align-items: flex-start;
    transform: unset;

    span {
        color: $grey-1;
        padding-top: 8px;
    }
    &:hover {
        cursor: pointer;
        z-index: 2;

        .hex,
        svg,
        circle {
            fill: $grey-1;
            transition: all 0.3s ease-in-out;
        }

        span {
            color: $white;
        }
    }
}
