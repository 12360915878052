@import '~@beewise/theme/build/variables';

.yard-modal {
  .modal-body {
    min-height: 60vh;
    max-height: 60vh;
    overflow-y: auto;
  }
  .yard-section {
    display: flex;
    gap: 30px;
  }
  .yard-column {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 50%;

    .yard-textarea {
      height: 100%;

      .text-field-container {
        height: 100%;

        .text-field {
          height: 100%;
          resize: none;
        }
      }
    }
  }

  .yard-row {
    display: flex;
    gap: 30px;
  }

  .add-another-year {
    border: none;
    width: fit-content;

    &:hover {
      background: transparent;
    }
  }

  .delete-button {
    margin-right: auto;
  }

  .yard-year {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 30px;

    .remove-year {
      position: absolute;
      right: 0;
      top: -5px;
    }

    .text-field-root:has(.text-field:disabled) .text-field, .text-field-root:has(.text-field:disabled) .text-field-label {
      color: $gray-100;
    }
  }
}
