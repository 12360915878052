@import "~@beewise/theme/build/variables";

.block-label {
  &-item {
    background-color: $beewise-yellow;
    color: $white;
    padding: 8px;
    border-radius: 4px;
    position: relative;
    transform: translate(-50%, -50%);
    font-size: 14px;
    font-family: 'Poppins', serif;
  }
}
