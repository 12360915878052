@import '~@beewise/theme/build/variables';

.select-badge {
  border: 0;

  min-width: 95px;

  svg {
    width: 16px;
    height: 16px;
  }

  .select-field__control  {
    border: 0;
    border-radius: 4px;
    min-height: 26px;

    &:hover {
      background: $secondary-blue;
    }
  }

  .placeholder {
    color: $static-partition;
    display: flex;
    align-items: center;
    svg {
      margin-right: 4px;
    }
  }

  .select-field__indicators:before {
    border-top-color: $static-partition;
    transform: translateY(-50%)!important;
  }

  .select-field__value-container {
    padding: 4px 8px;
  }

  .select-field__menu {
    min-width: 212px;
    width: 110%;
    cursor: pointer;
    svg {
      padding: 2px 0;
      width: 16px;
      height: 20px;
      stroke: $gray-60;
    }
  }

  .select-field__option {
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 4px;
    height: fit-content;
  }

  .select-field__option--is-focused {
    background: $secondary-blue;

    svg {
      stroke: $primary-blue;
    }

    .name-block-name {
      color: $primary-blue;
    }
  }
  .icon {
    display: inline-block;
  }

  .tooltip-block {
    display: flex;
    gap: 8px;

    &.disabled, &.disabled:hover {
      svg {
        stroke: $grey-5;
      }
      .name-block-name, .name-block-title {
        color: $grey-5;
      }
    }
  }
}
