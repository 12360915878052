@import '~@beewise/theme/build/variables';

.yard-tooltip {
  background: $white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 16px;
  gap: 12px;
  box-shadow: 0px 0px 8px $grey-4;
  border-radius: 8px;
  width: 300px;

  &-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-bottom: 1px solid $gray-30;
    padding-bottom: 12px;
    width: 100%;

    &-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: $black;
    }
  
    &-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
  
      &-block {
        display: flex;
        gap: 8px;   
      }
  
      &-badge {
        display: flex;
        align-items: center;
        padding: 4px;
        background: $purple;
        border-radius: 4px;
        font-size: 14px;
        line-height: 21px;
        color: $black;
        gap: 4px;
  
        &-warning {
          background: $pale-pink;
          color: $fiery-rose;
          display: flex;
          align-items: center;
          padding: 4px;
          border-radius: 4px;
          font-size: 14px;
          line-height: 21px;
          gap: 4px;
        }
  
        &-icon {
          width: 16px;
          height: 16px;
        }
      }

      &-data {
        display: flex;
        align-items: center;
        padding: 4px;
        border-radius: 4px;
        font-size: 12px;
        line-height: 18px;
        color: $black;
        gap: 4px;

        &-icon {
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  &-line {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;

    &-block {
      display: flex;
      justify-content: space-between;
      gap: 8px;
    }

    &-badge {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px;
      min-width: 130px;
      background: $purple;
      border-radius: 8px;

      &-header {
        display: flex;
        gap: 4px;
        
        &-icon {
          width: 16px;
          height: 16px;
          transform: scale(1.2);
          color: $black;
        }
        
        &-subtitle {
          font-size: 12px;
          line-height: 18px;
          color: $black;
        }
      }
      
      &-text {
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: $gray-100;

        .workspace-list-item-icon {
          margin-left: 4px;
      }
      }
    }
  }
}
