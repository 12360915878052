@import '~@beewise/theme/build/variables';

.activity-right-panel.ui.right.sidebar {
    width: $right-panel-width;
    box-sizing: border-box;
    border: none;
}

.activity-right-panel.ui.right.sidebar.visible {
    will-change: unset;
    transform: none;
}

.aside {
    font-family: Poppins, serif;
    font-size: 13px;

    $header-height: 60px;
    background-color: $white;
    height: auto;
    z-index: 1;
    border-left: 1px solid $gray-04;
    display: flex;
    flex-direction: column;

    &-content {
        width: $right-panel-width;
        flex: 1;

        .custom-tab .tab-list li {
            font-size: 16px;
        }

        .custom-tab .tab-list li.active {
            font-weight: 600;
            color: $primary-blue;
            border-bottom-width: 2px;
            border-bottom-color: $primary-blue;
        }
        

        .right-menu-block {
            padding-top: 24px;

            &-header {
                color: $red-brick;
                font-size: 12px;
                font-weight: 600;
                font-family: 'Poppins', serif;
                text-transform: uppercase;
                margin-bottom: 12px;
                padding: 0 16px;
            }
        }
    }

    &-overflow {
        display: none;
        opacity: 0;
    }

    .aside-menu {
        &-item {
            color: $dark-grey;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            path {
                stroke: $dark-grey;
            }

            &.active {
                color: $blue-old;
                // TODO: change to pointer on all items
                cursor: pointer;

                path {
                    stroke: $blue-old;
                }
            }

            &.disabled {
                cursor: not-allowed;
            }
            &.hovered {
                background-color: $grey-4;
            }
        }

        &-title {
            margin-top: 4px;
        }
    }
}
