@import '~@beewise/theme/build/variables';

.tooltip-card {
  position: absolute;
  font-family: 'Poppins', serif;
  padding: 12px 16px;
  background-color: $white;
  box-shadow: 0 15px 30px $gray-02;
  border-radius: 8px;
  cursor: default;
  min-width: 274px;
  z-index: 2;
  transition: all 500ms;

  &.show-left {
    margin-left: unset;
  }
}

.tooltip-card.preEnter, .tooltip-card.exiting{
  opacity: 0;
  transition: all 500ms;
}

.tooltip-card.hidden {
  display: none !important;
}


