@import '~@beewise/theme/build/variables';

.right-menu-block.log,
.right-menu-block.activities,
.right-menu-block.statistic {
    padding-top: 24px;

    .right-menu-block-header {
        display: flex;
        color: #d67567;
        font-size: 14px;
        font-weight: 600;
        font-family: 'Poppins', serif;
        text-transform: uppercase;
        margin-bottom: 12px;
        padding: 0 16px;

        .add-beekeeper-entry {
            margin-left: auto;
            width: 16px;
            height: 16px;
            color: $gray-07;
            cursor: pointer;
            margin-right: 8px;

            &:hover {
                padding: 4px;
                background-color: $gray-015;
                border-radius: 4px;
                margin: -4px 4px -4px auto;
            }
        }
    }

    .right-menu-block-body {
        padding: 0 16px;

        .ui.secondary.pointing.menu {
            min-height: unset;

            a.item {
                font-size: 14px;
                padding: 4px 16px 12px;
            }

            a.active.item {
                color: $kona-grey;
                border-bottom-width: 2px;
                border-bottom-color: $beewise-yellow;
            }
        }
    }
}

.beekeeper-entry {
    padding: 10px 8px 8px 8px;
    border-radius: 12px;
    background-color: $gray-01;
    margin-bottom: 8px;

    &-source {
        margin-left: 26px;
        font-size: 12px;
        color: $blue;
        line-height: 16px;
        margin-bottom: 2px;
        width: fit-content;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

    &-header {
        display: flex;
        line-height: 18px;
        margin-bottom: 4px;
        height: 20px;

        .note-icon {
            width: 16px;
            height: 16px;
            margin-right: 8px;
            color: $gray;
        }

        .pen-icon {
            width: 20px;
            height: 20px;
            color: $gray-07;
            cursor: pointer;
            display: none;

            &:hover {
                padding: 4px;
                margin-top: -4px;
                margin-right: -4px;
                margin-left: -4px;
                border-radius: 4px;
                background-color: $gray-015;
            }
        }
    }

    &-title {
        font-size: 14px;
        color: $gray;
    }

    &-author {
        font-size: 10px;
        color: $gray-06;
        margin-left: auto;
        margin-right: 10px;
    }

    &-content {
        display: flex;
        line-height: 16px;
        margin-bottom: 4px;
        min-height: 20px;
        position: relative;

        .circle-minus-icon {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            width: 20px;
            height: 20px;
            color: $gray-07;
            cursor: pointer;
            margin-left: auto;
            display: none;
            margin-top: 4px;

            &:hover {
                padding: 4px;
                background-color: $gray-015;
                border-radius: 4px;
                margin-right: -4px;
            }
        }
    }

    &-message {
        font-size: 12px;
        color: $gray;
        margin-left: 26px;
    }

    &-footer {
        display: flex;
        line-height: 14px;

        .clock-icon {
            width: 12px;
            height: 12px;
            margin-left: 2px;
            color: $gray-06;
        }
    }

    &-date {
        font-size: 10px;
        color: $gray-06;
        margin-left: 12px;
    }

    &.low {
        border: 1px solid $blue;

        .note-icon {
            color: $blue;
        }
    }

    &.medium {
        border: 1px solid $beewise-yellow;

        .note-icon {
            color: $beewise-yellow;
        }
    }

    &.high {
        border: 1px solid $red;

        .note-icon {
            color: $red;
        }
    }

    &:hover {
        .pen-icon,
        .circle-minus-icon {
            display: block;
        }
    }

    &:first-of-type {
        margin-top: 8px;
    }
}

.new-pane {
    position: relative;
    z-index: 1;

    &.collapsed {
        .new-pane-alert {
            &:nth-child(1) {
                position: relative;

                &:hover {
                    background-color: #dedbda;
                }

                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    padding: 10px 8px 8px 8px;
                    border-radius: 12px;
                    background-color: #f6f5f5;
                    border: 1px solid #d7d6d4;
                    width: 90%;
                    left: 50%;
                    bottom: 0;
                    height: 20px;
                    transform: translate(-50%, 8px);
                    z-index: -1;
                    visibility: visible;
                    opacity: 1;
                    transition: opacity 1s, visibility 1s;
                }
                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    padding: 10px 8px 8px 8px;
                    border-radius: 12px;
                    background-color: #fbfafa;
                    border: 1px solid #ebebea;
                    width: 83%;
                    left: 50%;
                    bottom: 0;
                    height: 20px;
                    transform: translate(-50%, 16px);
                    z-index: -1;
                    visibility: visible;
                    opacity: 1;
                    transition: opacity 1s, visibility 1s;
                }
            }
        }
        .new-pane-alert {
            &:nth-child(n + 2) {
                display: none;
            }
        }
    }
    &-fake {
        opacity: 0;
    }
    &-alert {
        padding: 10px 8px 8px 8px;
        border-radius: 12px;
        background-color: #ededec;
        margin-bottom: 8px;
        border: 1px solid #febb11;
        &:nth-child(1) {
            position: relative;
            &:after {
                content: '';
                display: block;
                position: absolute;
                padding: 10px 8px 8px 8px;
                border-radius: 12px;
                background-color: #f6f5f5;
                border: 1px solid #d7d6d4;
                width: 90%;
                left: 50%;
                bottom: 0;
                height: 20px;
                transform: translate(-50%, 8px);
                z-index: -1;
                visibility: hidden;
                opacity: 0;
                transition: opacity 0.3s, visibility 0.3s;
            }
            &:before {
                content: '';
                display: block;
                position: absolute;
                padding: 10px 8px 8px 8px;
                border-radius: 12px;
                background-color: #fbfafa;
                border: 1px solid #ebebea;
                width: 83%;
                left: 50%;
                bottom: 0;
                height: 20px;
                transform: translate(-50%, 16px);
                z-index: -1;
                visibility: hidden;
                opacity: 0;
                transition: opacity 1s, visibility 1s;
            }
        }

        &-header {
            display: flex;
            line-height: 18px;
            margin-bottom: 4px;
            height: 20px;

            .pane-alert-icon {
                width: 16px;
                height: 16px;
                margin-right: 8px;
                color: #febb11;
            }

            .circle-xmark {
                font-size: 18px;
                color: #69605c;
                margin-top: 1px;
                margin-left: 1px;

                &-wrapper {
                    width: 20px;
                    height: 20px;
                    padding: 2px;
                    border-radius: 4px;
                    margin-top: -3px;
                    cursor: pointer;
                    display: none;

                    &:hover {
                        background-color: #cfcbca;
                    }
                }
            }
        }

        &-title {
            font-size: 14px;
            color: RGB(78, 69, 64);
        }

        &-initiator {
            font-size: 10px;
            color: RGBA(78, 69, 64, 0.6);
            margin-left: auto;
            margin-right: 10px;
        }

        &-source {
            margin-left: 26px;
            font-size: 12px;
            color: RGB(0, 153, 204);
            line-height: 16px;
            margin-bottom: 2px;
            width: fit-content;

            &:hover {
                text-decoration: underline;
            }
        }

        &-text {
            font-size: 12px;
            color: RGB(78, 69, 64);
            line-height: 16px;
            margin-left: 26px;
        }

        &-footer {
            display: flex;
            line-height: 14px;
            margin-top: 8px;

            .clock-icon {
                font-size: 12px;
                color: #8e8986;
                margin-right: 12px;
                margin-left: 2px;
            }
        }

        &-date {
            font-size: 10px;
            color: #8e8986;
        }

        &.low {
            border: 1px solid $blue;

            .pane-alert-icon {
                color: $blue;
            }
        }

        &.medium {
            border: 1px solid #febb11;

            .pane-alert-icon {
                color: #febb11;
            }
        }

        &.high {
            border: 1px solid #f2292a;

            .pane-alert-icon {
                color: #f2292a;
            }
        }

        &.with-source:hover {
            background-color: #dddbd8;
            cursor: pointer;
        }

        &:first-of-type {
            margin-top: 8px;
        }

        &:hover {
            .circle-xmark-wrapper {
                display: block;
            }
        }
    }

    .pagination-button {
        margin-top: 22px;
    }
}

.new-pane,
.new-pane.collapsed {
    &.single {
        .new-pane-alert:after,
        .new-pane-alert:before {
            display: none;
        }

        .new-pane-alert:hover {
            background-color: #ededec;
        }
    }

    &.double {
        .new-pane-alert:before {
            display: none;
        }

        .new-pane-alert:hover {
            background-color: #ededec;
        }
    }
}
