@import '~@beewise/theme/build/variables';

.search-box {
    position: absolute;
    max-width: 100%;
    top: 40px;
    left: 50%;
    width: calc(100% - 80px);
    z-index: 1;
    margin: auto;
    transform: translateX(-50%);
    opacity: 0.9;
    border-radius: 16px;
    height: 34px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    background: $light-grey;
    box-shadow: 0 4px 8px rgba(0, 0, 0, .25);

    &-input {
        border: unset;
        width: 100%;
        height: 100%;
        background-color: transparent;
        margin-left: 4px;
        font-size: 12px;
        color: $grey-3;

        &::placeholder {
            font-size: 12px;
            color: $gray-80;
            font-family: 'Poppins', serif;
        }

        &:focus {
            outline: none;
        }
    }

    &-icon {
        margin-left: 12px;
        color: $gray-80;
    }

    &-clear {
        margin-left: 4px;
        color: $gray-80;
        cursor: pointer;
    }
}

.pac-container.pac-logo.hdpi {
    width: 300px !important;
}
