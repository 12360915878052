@import '~@beewise/theme/build/variables';

.right-panel-content {
  background-color: $white;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    &-title {
      font-size: 20px;
      font-weight: bold;
      margin: 0;
      color: $black;
    }

    &-close-icon {
      cursor: pointer;
      font-size: 20px;
      color: $black;
    }
  }

  &-section {
    overflow-y: auto;
    width: 100%;

    &-post-list {
      margin: 0;
      padding: 0;
      list-style: none;

      &-post {
        margin-bottom: 8px;
        background-color: $gray-10;
        padding: 8px;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;

        &:last-child {
          margin-bottom: 0;
        }

        &-title {
          font-size: 20px;
          color: $black;
          margin: 12px 0;
          font-weight: bold;
        }

        &-subtitle {
          margin: 4px 0;
          color: $grey-3;
          font-size: 16px;
          text-align: center;
          font-weight: 500;
        }

        &-text {
          margin: 4px 0;
          color: $black;
          font-size: 12px;
        }

        &-cover {
          width: 100%;
          height: 240px;
          border-radius: 8px;
          object-fit: cover;
          object-position: center;
        }

        &-author-info {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 2px;

          &-author-img {
            width: 20px;
            border-radius: 8px;
          }
        }

        &-tags {
          margin-top: 12px;

          &-tag {
            display: inline-block;
            background-color: $grey-6;
            color: $grey-8;
            padding: 4px 12px;
            border-radius: 12px;
            font-size: 12px;
            margin-right: 8px;
          }
        }

        &-info {
          display: flex;
          gap: 12px;

          span {
            font-weight: 600;
          }
        }
      }
    }

    &-info {
      font-size: 20px;
      color: $black;
      margin: 20px 0;
      font-weight: 500;
      text-align: center;
      width: 100%;
    }
  }

  strong {
    font-weight: bold;
    display: inline-block;
    margin: 8px 0;
  }
}