@import '~@beewise/theme/build/variables';

.bhome-item {
  box-sizing: border-box;
  padding: 16px;
  background: $gray-0;
  border-bottom: 1px solid $grey-7;
  cursor: pointer;
  display: flex;
  gap: 12px;
  flex-direction: column;
  transition: all 0.1s ease-out;

  &.active, &:hover {
    background-color: $purple;
  }

  .badge {
    font-weight: 600;
    padding: 4px;
    &.badge-default {
      color: $black;
    }
    svg {
      height: 20px;
    }
  }


  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;


    &-title {
      font-weight: 400;
      font-size: 14px;
      color: $text-secondary;
    }

    &-icons {
      display: flex;
      gap: 4px;
    }
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
