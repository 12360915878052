@import '~@beewise/theme/build/variables';

$checkbox-size: 8px;
$icon-size: 10px;

$checkbox-size-small-screen: 6px;
$icon-size-small-screen: 8px;

$frame-width-4k: 9px;
$frame-width-large: 7px;
$frame-width-medium: 6px;
$frame-width-small: 5px;
$frame-width-xs: 4px;

.station-frame {
  min-width: $frame-width-4k;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: $gray-30;
  height: 100%;
  cursor: pointer;
  border-radius: 1px;

  @media only screen and (min-width: 2048px) {
    min-width: $frame-width-4k;
  }

  @media only screen and (max-width: 2047px) {
    min-width: $frame-width-large;
  }

  @media only screen and (max-width: 1919px) {
    min-width: $frame-width-medium;
  }

  @media only screen and (max-width: 1599px) {
    min-width: $frame-width-small;
  }

  @media only screen and (max-width: 1366px) {
    min-width: $frame-width-xs;
  }

  @media only screen and (max-width: 1023px) {
    min-width: $frame-width-small;
  }

  .comb-frame {
    border-radius: 1px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    overflow: hidden;
  }

  &:hover {
    box-shadow: 0 0 6px 0 $gray-50;
  }

  &-wrapper {
    position: absolute;
    top: 0;
    height: 100%;

    &-outside {
      opacity: .3;
    }

    &-static-partition {
      .station-frame {
        min-width: calc($frame-width-4k / 2);

        @media only screen and (min-width: 2560px) {
          min-width: calc($frame-width-4k / 2);
        }

        @media only screen and (max-width: 2559px) {
          min-width: calc($frame-width-large / 2);
        }

        @media only screen and (max-width: 1919px) {
          min-width: calc($frame-width-medium / 2);
        }

        @media only screen and (max-width: 1439px) {
          min-width: calc($frame-width-small / 2);
        }

        @media only screen and (max-width: 1023px) {
          min-width: calc($frame-width-xs / 2);
        }
      }
    }

    &-inspected {
      border: 1px solid $primary-blue;
      box-sizing: border-box;
    }

    .station-frame-checkbox.ui.fitted.checkbox.beewise-checkbox {
      min-height: unset;
      min-width: unset;
      line-height: unset;
      width: 10px;
      height: $checkbox-size;
      margin: 2px 0;
    }

    .ui.checkbox.beewise-checkbox input[type='checkbox'] {
      width: $checkbox-size;
      height: $checkbox-size;
    }

    .ui.checkbox.beewise-checkbox label:before {
      width: $checkbox-size;
      height: $checkbox-size;
      background-color: unset;
      border: 1px solid $gray-04;
      border-radius: 2px;
    }

    .ui.checkbox.beewise-checkbox input[type='checkbox']:focus ~ label:before {
      background-color: unset;
      border: 1px solid $gray-04;
      border-radius: 2px;
    }

    .ui.checkbox.beewise-checkbox input[type='checkbox']:checked ~ label:before {
      background-color: $beewise-yellow;
      border: 1px solid $beewise-yellow;
      border-radius: 2px;
    }

    .ui.checkbox.beewise-checkbox input[type='checkbox']:checked ~ label:after {
      width: $icon-size;
      height: $icon-size;
      font-size: 6px;
      line-height: $icon-size;
      color: $white;
    }

    .station-frame-exclamation-square {
      width: $checkbox-size;
      height: $checkbox-size;
      color: $red;
      cursor: pointer;
    }

    .station-frame-alert-icon {
      font-size: 12px;
      color: #cd1c00;
      pointer-events: none;
    }

    .station-frame-status-icon {
      font-size: 8px;
      color: $gray-04;

      &.with-checkbox {
        top: -44px;
      }

      &.alerted {
        path {
          fill: $bad;
        }
      }

      &.alerted + .station-frame-status-icon {
        margin-top: 2px;
      }
    }
  }

  &-empty {
    cursor: default;
  }
}
