@import '~@beewise/theme/build/variables';

.bhome {
  background: $purple;
  height: 100%;
  flex: 2 1 0;
  padding: 24px 16px 24px 12px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;
  box-sizing: border-box;

  &-public {
    padding-left: 20px;

    .small-hive-header-block .checkbox-icon {
      display: none;
    }
  }

  &-header {
    display: flex;
    align-items: flex-end;
    align-items: center;
    flex: 1;
    gap: 8px;
    background: inherit;

    .beewise-toggle {
      margin-left: auto;
    }

    &-wrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;
      position: relative;
      background: inherit;
    }

    .notification-icon {
      margin-left: auto;
      position: relative;
      top: 4px;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: inherit;

      svg {
        width: 16px;
        height: 16px;
      }
    }

    .is-open {
      background: $purple-light;

      svg {
        color: $static-partition;
      }
    }
  }

  .subheader {
    gap: 12px;
    margin-bottom: 8px;
  }


  .empty-state {
    width: 100%;
    height: 100%;
  }
}

.notification-icon.is-notified {
  &::before {
    content: '';
    position: absolute;
    display: inline-block;
    box-sizing: border-box;
    width: 5px;
    height: 5px;
    background: $static-partition;
    border-radius: 50%;
    top: 6px;
    padding: 1px;
    right: 3px;
    z-index: 1;
  }

  &::after {
    content: '';
    position: absolute;
    box-sizing: border-box;
    display: inline-block;
    width: 7px;
    height: 7px;
    background: inherit;
    border-radius: 50%;
    top: 6px;
    padding: 1px;
    right: 4px;
  }
}