@import '~@beewise/theme/build/variables';

.modal-wrapper.full-screen-image-modal-updated {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    overflow: hidden;
    margin: 0;
    z-index: 9999;

    .modal-content {
        width: 1140px;
        background: $white;
        border-radius: 12px;
        display: flex;
        padding: 0;

        .modal-header {
            padding: 0;
            display: none;
        }

        .modal-body {
            overflow: hidden !important;
            max-height: 100%;
            padding: 0;
        }

        .full-screen-image-modal {
            overflow: hidden !important;
            padding: 16px;
            display: flex;
            flex-direction: column;
            gap: 16px;
            max-height: 100%;

            .magnifier {
                display: block;
                height: 440px;

                &-image {
                    border-radius: 16px;
                    height: 440px;
                }
            }

            .full-screen-image-modal-top {
                display: flex;
                justify-content: space-between;

                &-left {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 8px;

                    &-icon {
                        width: 16px;
                        height: 16px;
                        cursor: pointer;
                    }

                    &-title {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        color: $black;
                    }
                }
            }

            .full-screen-image-modal-content {
                position: relative;

                .modal-direction-icon {
                    width: 12px;
                    height: 12px;
                    top: calc(50% - 24px/2);
                    background: rgba(255, 255, 255, .8);
                    border-radius: 19px;
                    padding: 6px;
                    color: $black;
                    cursor: pointer;
                    z-index: 2;
                    position: absolute;
                }

                &-left {
                    left: 1%;
                }

                &-right {
                    right: 1%;
                }
            }

            .full-screen-image-modal-bottom {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 12px;
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                color: $grey-3;
                padding-top: 16px;
                border-top: 1px solid $grey-6;
            }
        }
    }
}