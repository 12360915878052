@import '~@beewise/theme/build/variables';
@import '~@beewise/theme/build/reusables';

.invitation {
    @extend .white-box;
    padding: $spacing;

    & > div {
        margin-bottom: 2 * $spacing;

        h3 {
            font-size: 18px;
        }
    }

    .error {
        margin-bottom: 10px;
        color: $bad;
    }

    & > div:last-child {
        margin-bottom: 0;
    }

    &-permissions {
        display: flex;
        justify-content: flex-start;
        margin-top: $spacing;

        & > div:last-child {
            margin-left: $spacing;
        }
    }

    &-button-container {
        display: flex;
        justify-content: flex-end;
    }
}
