@import '~@beewise/theme/build/variables';

.online-widget-wrapper {
  .beewise-preloader {
    transform: scale(50%);
  }

  .online-widget {
    flex-direction: column;
    justify-content: space-between;

    &-header {
      color: $text-secondary;
    }

    &-content {
      margin-top: 16px;
      display: flex;
      flex-direction: column;

      &-numbers {
        display: flex;
        justify-content: space-between;

        &-online {
          color: $text-primary;
          font-size: 16px;
          font-weight: 600;
          display: flex;
          align-items: center;

          &-percent {
            margin-left: 8px;
            background: rgba(215, 215, 215, 1);
            box-sizing: border-box;
            padding: 4px 8px;
            border-radius: 16px;
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
    }
  }
  .progress-container {
    margin-top: 8px;
  }
}
