@import '~@beewise/theme/build/variables';

#hives {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 12px;
    grid-row-gap: 12px;

    .hive:nth-of-type(4n) {
        .preview-frame-card {
            right: 18px;
            left: unset;
        }
    }
}

.hives {
    padding-bottom: 16px;
    padding-top: 16px;
    display: flex;
    width: 100%;
    height: 100%;
    max-height: 912px;
    box-sizing: border-box;

    .side-a,
    .side-b {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(7, 1fr);
        grid-column-gap: 16px;
        grid-row-gap: 16px;
    }

    .side-a {
        padding-left: 16px;
    }

    .side-b {
        padding-right: 16px;
        margin-left: 60px;
    }

    .status-icons-wrapper,
    .containers-wrapper {
        grid-column: 2;
        grid-row: 5;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .status-icons-wrapper {
        grid-row: 4;
    }

    &.hive-stations {
        display: flex;
        justify-content: center;
        height: auto;
    }

    .hive-stations-container {
        box-sizing: border-box;
        width: 98%;
        background: $white;
        box-shadow: 0 4px 16px 8px rgba(0, 0, 0, .05);
        border-radius: 20px;
        padding: 32px;
        display: flex;
        flex-direction: column;

        .hive-stations-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 6px;
            position: relative;
            bottom: 12px;

            &-item {
                display: flex;
                align-items: center;
                gap: 4px;
            }
        }
    }

    .modal {
        box-shadow: none;
    }

    .hive-stations-content {
        box-sizing: border-box;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .hive-syrup-container {
            min-height: 400px;
            min-width: 20px;
            max-width: 20px;
            background-color: $gray-30;
            writing-mode: vertical-rl;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $text-primary;
            font-size: 14px;
            position: relative;
        }

        .hive-syrup-level {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 20px;
            box-sizing: border-box;
            background-color: $feeder-new;
        }

        .hive-syrup-text {
            z-index: 1;
        }

        .hive-stations-lines {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            width: 100%;
        }

        .hive-stations-line {
            display: flex;
        }

        .hive-stations-frames {
            width: 100%;
            margin: 0 32px;
        }

        .hive-stations-name {
            min-height: 160px;
            color: $text-primary;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            font-weight: 500;
        }
    }
}

@media screen and (max-width: 1320px) {
    .hives {
        /* TODO: return on left panel remove */
        //width: calc(100vw - #{$right-panel-width});
        width: calc(100vw - #{$left-panel-width} - #{$right-panel-width});
    }
}

@media screen and (min-width: 1320px) {

    .side-a,
    .side-b {
        width: 50%;
    }
}

@media screen and (max-height: 800px) {
    .hives {
        height: auto;
    }
}