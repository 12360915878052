@import '~@beewise/theme/build/variables';
@import 'src/components/reusables/Hive/FrameColors';

$hive-block-height: 404px;
$storage-space-container-width: 108px;

.hive-managment-modal {
    &.modal-wrapper {
        .modal-content {
            width: 100%;
        }
    }
}

.hive-managment-modal .modal-body {
    height: 100%;
    max-height: 85vh;
    padding: 0;

    .hives .hive-stations-content {
        box-shadow: none;
        padding: 0;
        width: 100%;
    }

    .body {
        padding: 32px 32px 0 32px;
    }

    .legend {
        width: fit-content;
        padding: 35px 0 0 0;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }

    .footer {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        justify-content: space-between;
        padding: 20px;
        border-top: 1px solid $gray-40;

        .text {
            font-size: 13px;
            font-weight: 400;
        }

        .buttons {
            display: flex;
            flex-direction: row;
            gap: 8px;
        }
    }

    .hive-block-frames {
        display: flex;
        margin-top: 0;
        padding: 80px 56px 80px 0;
        justify-content: center;
        align-items: center;
        height: $hive-block-height;

        .hive-block-frame-item-wrapper {
            max-width: 30px;

            .hive-block-frame-item {
                height: 400px;
                display: flex;
                flex-direction: column-reverse;
                cursor: pointer;
                background-color: $off-white;
                position: relative;
                margin: 0 3px;

                &.before-partition {
                    opacity: .3;
                }

                &.isDisabled {
                    cursor: not-allowed;
                }

                &.isDragging {
                    cursor: grabbing;
                }

                &.isMoved {
                    border: 1.5px solid $primary-blue;
                }
            }
        }
    }

    .storage-space-container {
        display: flex;
        flex-direction: column;
        width: $storage-space-container-width;
        height: $hive-block-height;

        .storage-space-content {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }

        .text {
            color: $text-secondary;
            text-align: right;
            font-size: 13px;
            font-weight: 400;
        }
    }

    .storage-space-placeholder {
        width: 108px;
        height: $hive-block-height;
        visibility: hidden;
    }
}


