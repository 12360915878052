@import '~@beewise/theme/build/variables';

.modal-wrapper.add-log-entry-modal {
    .modal-content {
        width: 400px;

        .modal-header {
            padding: 4px 8px;
            height: 30px;
            background-color: $gray-01;

            &__text {
                font-size: 14px !important;
                color: $gray;
            }

            .btn.modal-close {
                background-color: unset;

                .icon.button-icon.modal-close-icon {
                    width: 24px;
                    height: 24px;
                }
            }
        }

        .modal-body {
            padding: 24px 16px 16px 16px;
        }

        .add-log-entry-empty-message-warning {
            font-size: 12px;
            color: $red;
        }

        .add-log-entry-modal-label {
            font-size: 16px;
            font-weight: 600;
            color: $gray;
            margin-bottom: 4px;

            &.priority-label {
                margin-top: 24px;
            }
        }

        .add-log-entry-modal-text {
            border: unset;
            resize: none;
            width: 368px;
            height: 64px;
            background-color: $gray-005;
            border-radius: 4px;
            font-family: 'Poppins', serif;
            padding: 8px;
            box-sizing: border-box;
        }
        .add-log-entry-modal-option-text {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .alert-icon {
                margin-right: 8px;
            }

            .medium-option {
                color: $beewise-yellow;
            }
            .low-option {
                color: $blue;
            }
        }

        .add-log-entry-modal-select {
            width: 100%;
            margin-bottom: 8px;
            max-height: 38px;
            display: flex;
            align-items: center;
        }

        .add-log-entry-modal-tip {
            font-size: 10px;
            color: $gray-06;
            margin-bottom: 8px;
        }

        .add-log-entry-modal-actions {
            display: flex;
            justify-content: flex-end;
            margin-top: 16px;

            .cancel {
                font-size: 16px;
                background-color: unset;
                color: $gray;
                border: 1px solid $yellow-dark;
                width: 88px;
                height: 32px;
            }

            .add {
                font-size: 16px;
                margin-left: 16px;
                background-color: $beewise-yellow;
                color: $gray;
                width: 64px;
                height: 32px;
            }
        }
    }
}
