@import '~@beewise/theme/build/variables';

$FILTER_WIDTH: 260px;
$DROPDOWN_WIDTH: 90px;

.left-panel {
    background: $white;
    width: $FILTER_WIDTH;
    display: flex;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;

    &-wrapper {
      z-index: 2;
      background-color: $purple;
      padding-right: 12px;

      &.opened {
        filter: drop-shadow(0 6px 30px $blue-shadow);
      }
    }

    &-icon {
      position: absolute;
      bottom: 10%;
      padding: 12px 8px;
      background: $white;
      border-radius: 0 40px 40px 0;
      transition: all .3s ease-out;
      transform: translateX(0%);
      box-shadow: 0 0 14px rgba(226, 229, 240, .8);

      &.opened {
        transform: translateX($FILTER_WIDTH);
      }
    }

    &-header {
        padding: 16px 12px 0 12px;
        box-sizing: border-box;
        color: $black;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
        min-height: 44px;
        width: $FILTER_WIDTH;

        &-block {
          display: flex;
          justify-content: start;
          align-items: center;
          gap: 8px;
          padding: 4px 0;

          &-tooltip-text {
              text-align: start;
          }
        }
    }

    &-filter {
      padding: 16px;
      border-bottom: 1px solid $grey-7;

        &-block {
          .checkbox-label {
            font-size: 14px;
          }

          &-row {
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
          }

          &-dropdown.select-field-root {
            width: 100%;
            min-width: $DROPDOWN_WIDTH;
            flex: 1;

            &.select-field-root .select-field__single-value {
              text-overflow: ellipsis;
              overflow: hidden;
            }

            &:nth-of-type(2){
                min-width: 120px;
                flex: 2;
            }

            .select-field__control {
              min-width: $DROPDOWN_WIDTH;
              width:100%;
            }
          }
        }

        &-wrapper{
            display: flex;
            justify-content: space-between;
            gap: 4px;
            width: 100%;
        }
    }
}
