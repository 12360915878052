@import '~@beewise/theme/build/variables';
$activity-width: 452px;

.modal-wrapper.full-screen-image-modal {
    justify-content: flex-start;
    align-items: center;
    width: calc(100% - #{$activity-width});
    height: calc(100% - 52px);
    margin-top: 54px;
    background-color: $gray-08;
    overflow: hidden;

    .modal-content {
        padding: 0;
        width: 100%;

        .modal-header {
            padding: 0;
            display: none;
        }

        .modal-body {
            overflow: scroll;

            .magnifier {
                display: block;
            }
        }
    }

    .full-screen-image-modal-label {
        position: absolute;
        top: 16px;
        left: 16px;
        font-size: 16px;
        font-weight: 600;
        color: white;
    }

    .full-screen-image-modal-close {
        font-size: 18px;
        color: white;

        &-wrapper {
            position: absolute;
            top: 0;
            right: 0;
            width: 48px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border-radius: 0;
            z-index: 1;
            background-color: $kona-grey;
        }
    }

    .increase-button {
        font-size: 18px;
        color: $black;

        width: 20px;
        height: 20px;
        padding: 6px;

        &-container {
            position: absolute;
            bottom: 0;
            right: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border-radius: 0;
            z-index: 1;
        }
        &:hover {
            background-color: $kona-grey;
            color: $white;
        }
    }
}
