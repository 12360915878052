@import '~@beewise/theme/build/variables';

.alert-icon {
    position: relative;
    cursor: pointer;
    color: $bad;
    padding: 4px;
    box-sizing: border-box;
    border-radius: 4px;
    width: 24px;
    height: 24px;
    &:hover {
        background-color: $grey-8;
    }

    &.svg-inline--fa.fa-w-16 {
        width: 24px;
        height: 24px;
    }
}
