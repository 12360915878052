@import '~@beewise/theme/build/variables';

.frame-panel-main-block {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 8px;

      &-title {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: $gray-09;
      }

      &-date {
        display: flex;
        align-items: center;
        gap: 4px;

        svg {
          width: 16px;
          height: 16px;
        }

        &-info {
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          color: $gray-09;
        }
      }
    }

    &-images {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .hive-block-inspected-frame-scan-a > div:first-child, .hive-block-inspected-frame-scan-b > div:first-child {
        border-radius: 8px;
        height: 136px;
      }

      .full-screen {
        outline: 2px solid $static-partition;
        border-radius: 8px;
      }
    }
  }