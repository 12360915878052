@import '~@beewise/theme/build/variables';

.message-item {
  box-sizing: border-box;
  background: $purple;
  border-radius: 12px;
  padding: 8px 12px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 4px;
  transition: all .1s ease-in-out;
  min-height: 64px;
  justify-content: space-between;

  &:hover {
    background: $purple-light;

    .message-item-cancel {
      display: block;
    }

    .badge,
    .message-item-status {
      background-color: $purple;
      color: $gray-80;
    }
  }

  &-title {
    gap: 8px;

    svg {
      width: 18px;
      height: 18px;
    }

    .message-item-cancel {
      display: none;
      width: 10px;
      height: 10px;
      padding: 4px;
      background: $white;
      border-radius: 50%;
    }
  }

  &-subtitle {
    font-size: 12px;
    color: $gray-80;
    gap: 4px;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  &-date {
    margin-left: auto;
    font-size: 12px;
    color: $gray-80;
    gap: 8px;
    display: flex;
    align-items: center;
  }

  &-hive {
    font-size: 12px;
    color: $gray-80;
  }

  &-status {
    padding: 4px 12px;
    background: $soft-grey;
    border-radius: 5px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: $gray-60;
  }

  .hive-badge-container {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    max-width: 100%;
  }

  .hive-badge-container .badge {
    flex: 0 0 calc(16.66% - 4px);
    box-sizing: border-box;
  }
}