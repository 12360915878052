@import '~@beewise/theme/build/variables';


.location-card {

    .location-name {
        color: $grey-1;
        font-weight: 700;
        font-size: 14px;
        max-width: 214px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .bhome-item {
        cursor: pointer;
        color: $blue;
        font-size: 14px;
        font-weight: 500;
        &:hover {
            opacity: 0.5;
        }
    }
    &-header {
        display: flex;
        align-items: center;
        .icon {
            margin-left: auto;
        }
    }

    &-body {
        .bhome-select {
            margin-top: 9px;
        }
    }

    .menu-group {
        font-size: 12px;
        font-weight: 700;
        color: $terracotta;
        text-transform: none;
        height: 30px;
    }
    .menu-option {
        display: flex;
        justify-content: space-between;

        &-label {
            color: $grey-1;
        }
    }
}
