@import '~@beewise/theme/build/variables';
@import 'src/components/reusables/Hive/FrameColors';

.legend {
    grid-column: 1 / 3;
    margin-left: 10px;
    min-width: 288px;
    width: 50%;

    &:nth-of-type(2n) {
        margin-left: 30px;
    }

    &-wrapper {
        display: flex;
        width: 100%;
        padding-left: 16px;
        padding-top: 16px;
        min-height: 120px;
        box-sizing: border-box;

        &-v4 {
            width: 98%;
            margin: 0 auto;
            padding: 24px 30px;
            min-height: auto;
            background: $white;
            border-radius: 20px;
            box-shadow: 0 4px 16px 8px rgba(0, 0, 0, .05);
            margin-bottom: 24px;
        }
    }

    &-title {
        font-size: 14px;
        color: $red-brick;
        text-transform: uppercase;
        margin-bottom: 12px;
    }

    &-content {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 18px;
        grid-row-gap: 12px;

        .legend-octagon,
        .legend-rect {
            font-size: 12px;
            margin-right: 8px;
            min-width: 12px;
            min-height: 12px;
            border-radius: 50%;
        }

        .legend-octagon {
            &.empty {
                color: $gray-02;
                border: unset;
            }
        }

        .legend-rect {
            border: unset;
            border-radius: 4px;
        }

        &-item {
            display: flex;
            align-items: center;
            position: relative;

            &-label {
                font-size: 12px;
                color: $gray-09;
            }
        }

        &.frame-legend {
            grid-template-columns: repeat(2, 1fr);
            width: fit-content;
        }
    }

    &-v4 {
        width: auto;
        margin-left: 0;

        .legend-content .legend-rect {
            border-radius: 50%;

            &.empty-comb {
                color: $gray-02;
                border: unset;
            }
        }

        .legend-content {
            width: fit-content;
            grid-column-gap: 36px;
            grid-row-gap: 16px;
        }

        .legend-content.frame-legend {
            grid-template-columns: repeat(2, 1fr);
        }

        &:nth-of-type(2n) {
            margin-left: 0;
        }
    }
}

.not-scanned {
    align-items: flex-start;

    .legend-octagon {
        background-image: radial-gradient($gray-60 0%, $white 90%);
        margin-right: 8px;
        width: 12px;
        height: 12px;
    }

    .legend-content-item-center {
        display: flex;
        align-items: center;
    }
}

.legend-small {
    display: flex;
    align-items: center;
    gap: 16px;

    .legend-octagon {
        width: 8px;
        height: 8px;
        margin-right: 8px;
        border-radius: 50%;
    }
}
