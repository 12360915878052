@import '~@beewise/theme/build/variables';

.preferences {
    &-description {
        color: $grey-2;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        margin-bottom: 32px;
    }

    &-list {
        width: 604px;
    }

    &-list-item {
        display: flex;

        .item-header {
            display: flex;
            margin-bottom: 4px;
            align-items: baseline;
        }

        .item-name {
            font-size: 16px;
            font-weight: 400;
            color: $grey-1;
            line-height: 22px;
        }

        .item-label {
            height: 14px;
            color: $white;
            font-size: 10px;
            background-color: $stem-green;
            border-radius: 20px;
            line-height: 14px;
            padding: 0 6px;
            margin-left: 8px;
        }

        .item-description {
            font-size: 12px;
            font-weight: 400;
            color: $grey-2;
            line-height: 16px;
        }

        .item-extra-options {
            display: flex;
            font-size: 10px;
            margin-top: 8px;

            .ui.input > input {
                padding: 4px 8px;
                font-size: 10px;
                margin-right: 8px;
            }
            input {
                width: 50px;
            }

            .ui.label {
                background-color: $brand;
                color: $grey-1;
                font-weight: normal;
                line-height: 20px;
            }
        }

        .filler {
            flex: 1;
        }

        .item-controls {
            display: flex;
            align-items: center;

            .item-button {
                width: 70px;
                height: 20px;
                background-color: $white;
                border-radius: 12px;
                padding: 0;

                .button-text {
                    font-size: 12px;
                    color: $grey-2;
                    font-weight: 400;
                }

                &.button-active {
                    background-color: $brand;
                    cursor: default;
                    user-select: none;
                }

                &.button-loading {
                    opacity: 0.5;
                    cursor: default;
                    user-select: none;
                }

                &:first-of-type {
                    margin-right: 16px;
                }
            }
        }

        &:not(:last-of-type) {
            border-bottom: 1px solid $grey-6;
            padding-bottom: 8px;
        }

        &:not(:first-of-type) {
            padding-top: 8px;
        }
    }
}
