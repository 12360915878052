@import '~@beewise/theme/build/variables';

.unsaved-activities {
    background-color: $beewise-yellow;
    box-sizing: border-box;
    animation: unsaved-animation 0.3s;

    @keyframes unsaved-animation {
        0% {
            max-height: 0;
        }
        100% {
            max-height: 64px;
        }
    }

    .title {
        display: flex;
        align-items: center;
    }

    &-count {
        width: 20px;
        height: 16px;
        border-radius: 8px;
        background-color: $red;
        font-size: 14px;
        font-weight: 600;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;

        &.two-digits {
            padding: 2px;
            border-radius: 10px;
        }
    }

    &-label {
        font-size: 18px;
        font-weight: 600;
        color: $white;
        margin-left: 4px;
    }

    .accordion-arrow {
        margin-left: 4px;
        font-size: 18px;

        &.rotated {
            transform: rotate(90deg);
        }
    }

    &-discard-button.btn {
        margin-left: auto;
        background-color: unset;
        border: 1px solid $yellow-dark;
        border-radius: 4px;
        font-size: 16px;
        color: $gray-09;
        padding: 2px 16px;
        font-weight: normal;
    }

    &-run-button.btn {
        margin-left: 8px;
        border-radius: 4px;
        background-color: $white;
        font-size: 16px;
        color: $gray-09;
        padding: 2px 16px;
        font-weight: normal;
    }


    &-list {
        background-color: $beewise-yellow;
        padding: 8px 16px 16px 16px;
        border-radius: 0 0 12px 12px;
        box-shadow: 0 8px 8px 0 $gray-04;
        margin-bottom: 16px;

        &-item {
            width: 420px;
            height: fit-content;
            border-radius: 12px;
            background-color: #fef4dc;
            padding: 12px 10px;
            box-sizing: border-box;

            &-header {
                display: flex;
                align-items: center;

                &-icon {
                    font-size: 18px;
                    color: $gray-08;
                    width: 18px;
                }

                &-title {
                    font-size: 14px;
                    color: $gray-09;
                    margin-left: 8px;
                }
            }

            &-content {
                margin-top: 8px;
                overflow: auto;
                max-height: 384px;

                &-activity {
                    padding: 10px 8px;
                    background-color: $white;
                    box-sizing: border-box;
                    border-radius: 8px;
                    margin-bottom: 4px;
                    margin-left: 26px;
                    color: $blue;
                    cursor: pointer;
                    display: flex;

                    .move-frame-icon {
                        font-size: 12px;
                        color: $gray-08;
                        margin: 0 8px;
                    }
                }
            }

            &-time {
                display: flex;
                margin-top: 10px;
                align-items: center;

                &-icon {
                    font-size: 12px;
                    color: $gray-06;
                    margin-left: 3px;
                }

                &-est {
                    font-size: 10px;
                    color: $gray-06;
                    margin-left: 10px;
                }
            }
        }
    }

    &-entrance-icon {
        margin: 0 10px;
    }

    &-entrance-icon,
    &-gray-text {
        color: $gray;
    }

    &-entrance-all {
        cursor: default;
    }


    .accordion-title, .accordion-description {
        display: flex;
        align-items: center;
        gap: 12px;
    }
    .accordion-icon {
        display: none;
    }
}

.activity-confirmation-modal.modal-wrapper {
    .modal-content.modal-content-with-header {
        width: 336px;
        height: fit-content;

        header.modal-header {
            padding: 8px;
            background-color: $gray-01;

            h2.modal-header__text {
                font-size: 14px !important;
                color: $gray-09;
            }

            button.btn.btn.modal-close {
                padding: unset;
                background-color: unset;
            }
        }

        .modal-body {
            .activity-confirmation-modal-content {
                font-size: 12px;
                color: $gray-08;
            }

            .activity-modal-text + .activity-modal-text {
                margin-top: 8px;
            }

            .activity-modal-text {
                font-size: 12px;
                line-height: 1.2;
            }

            .activity-confirmation-modal-actions {
                display: flex;
                justify-content: flex-end;
                margin-top: 16px;
                gap: 16px;
            }
        }
    }
}

.harvesting-frames-alert-modal.activity-confirmation-modal.modal-wrapper {
    .modal-content.modal-content-with-header {
        .modal-body {
            .activity-confirmation-modal-actions {
                button.btn.activity-confirmation-modal-cancel-button {
                    width: auto;
                }
            }
        }
    }
}
