.thermal-graph {
    position: absolute;
    width: 100%;
    height: 50%;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    background-color: transparent;

    canvas {
        position: absolute;
    }
}