@import '~@beewise/theme/build/variables';

.reports {
    background-color: $grey-8;
    box-sizing: border-box;
    min-height: calc(100vh - #{$app-header-height});

    *,
    *:before,
    *:after {
        -webkit-box-sizing: inherit;
        box-sizing: inherit;
    }

    > div + div {
        margin-top: 32px;
    }

    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 64px 12px 114px;
        background-color: $white;
        margin-bottom: 32px;
        height: 54px;

        .ui.secondary.menu {
            margin: 0 16px 0 0;
        }
    }

    .card {
        background-color: $white;
        border-radius: 8px;
        box-shadow: 0 15px 30px $gray-015;
        padding: 24px 48px;
        margin: 0 48px;

        &-title {
            font-size: 18px;
            font-weight: 700;
            display: flex;
            color: $grey-2;

            &-round {
                width: 16px;
                height: 16px;
                border-radius: 50%;
                background-color: #dea8a6;
                margin-right: 8px;
                margin-left: 64px;
                margin-bottom: 16px;

                &.honey {
                    background-color: $grey-5;
                }
            }

            &.honey {
                color: $grey-5;
            }
        }

        &-selects {
            display: flex;
            margin-bottom: 0;

            .beewise-select > .divider.default.text {
                color: inherit;
            }
            .ui.selection.dropdown.beewise-select {
                min-width: 220px;
            }
        }

        &-body {
            padding: 16px 0;

            .dimmable {
                border: unset;
                box-shadow: unset;
            }
        }
    }

    .activities-list {
        margin-right: auto;

        .ui.checkbox.beewise-checkbox {
            margin-right: 16px;

            input {
                width: 14px;
                height: 14px;
            }

            input[type='checkbox'] ~ label {
                line-height: 16px;

                &:after {
                    font-size: 6px;
                    line-height: 14px;
                    width: 14px;
                    height: 14px;
                }
            }
        }
    }

    &-select {
        margin-right: 92px;
        margin-left: 44px;

        &.ui.dropdown {
            display: flex;
            align-items: center;
            font-size: 14px;
        }
    }

    &-select.ui.selection.dropdown,
    &-bhome-select.ui.selection.dropdown,
    &-hive-select.ui.selection.dropdown {
        min-height: auto;
        padding: 7px 15px;
        min-width: 220px;

        &.reports-bhome-select {
            display: flex;
            min-width: 210px;
            max-width: 210px;
            .text {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 170px;
            }
        }

        & > .dropdown.icon {
            top: 5px;
        }

        .menu > .item,
        .menu > .item > div {
            font-size: 12px;
            white-space: nowrap;
            color: $grey-1;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .divider.text {
            color: $grey-2;
        }

        &.disabled {
            background-color: $grey-8;
            .divider.text {
                color: $grey-5;
            }
        }
    }

    &-hive-select.ui.selection.dropdown {
        min-width: 150px;
    }

    &-hive-select {
        margin-left: 16px;
        width: 96px;

        &.ui.dropdown:not(.button) > .default.text {
            color: $grey-1;
        }
    }

    .honey-weight {
        margin-left: 8px;
        color: $grey-3;
        font-weight: normal;
        cursor: pointer;

        &-value {
            font-weight: 700;
        }
    }

    .brood-frame-count {
        margin-left: 8px;
        color: $grey-3;
        font-weight: 700;
    }

    .chart-container {
        height: 35vh;
    }

    .empty-chart {
        min-height: 459px;
    }

    .brood-chart-dimmer .content {
        width: 100%;
        height: 100%;
    }

    .ui.secondary.menu {
        .item {
            font-size: 12px;
            color: $black;
            padding: 9px 12px;

            &.active {
                font-weight: 700;
            }
        }
    }

    .ui.three.column.grid {
        .card-header {
            padding: 20px 24px;
            align-items: center;
        }
    }
}

.custom-tooltip {
    background-color: $grey-1;
    box-shadow: 0 0 0 $gray-01, 0 2px 4px $gray-02, 0 8px 24px $gray-02;
    border-radius: 4px;
    padding: 8px;
    transform: translate(-55%, -100%);
    margin-top: -15px;

    &:before {
        content: ' ';
        background-color: $grey-1;
        position: absolute;
        width: 10px;
        height: 10px;
        bottom: -4px;
        transform: rotate(45deg);
        margin-left: -5px;
        left: 50%;
        top: auto;
        right: auto;
    }

    .content {
        color: $white;
        text-transform: capitalize;

        span {
            text-transform: lowercase;
        }
    }
}

.max-zoom-level {
    .recharts-xAxis {
        .recharts-cartesian-axis-tick:last-child {
            display: none;
        }
    }
}

.ui .form {
    .fields.brood-selects {
        margin: 0;
        font-size: 14px;
        justify-content: flex-end;
    }
}

.ui-chart-tooltip {
    position: absolute;
    top: 0;
    transition: all 0.5s ease-in-out 0.5s;
}
