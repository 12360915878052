@import '~@beewise/theme/build/variables';

.bhome-actions {
    padding: 8px 16px;
    border-bottom: 1px solid $gray-01;
    gap: 16px;
    display: flex;
    max-height: 42px;

    &-icon.svg-inline--fa {
        color: $gray-08;
        display: block;
        cursor: pointer;
        width: 16px;
        height: 16px;
        box-sizing: border-box;
        padding: 4px;
        margin: -4px 4px;

        &.is-active {
            background-color: $beewise-yellow;
            color: $white;
            border-radius: 4px;
        }

        &.disabled {
            color: $gray-04;
            cursor: default;
        }

        &:hover:not(.disabled):not(.is-active):not(.show-history) {
            border-radius: 4px;
            background-color: $gray-015;
            padding: 4px;
            cursor: pointer;
            margin: -4px 4px;
        }

        &.show-history {
            margin-left: auto;

            &:hover:not(.disabled) {
                border-radius: 4px;
                background-color: $gray-015;
                padding: 4px;
                cursor: pointer;
                margin-top: -4px;
                margin-right: 4px;
                margin-bottom: -4px;
            }
        }
    }

    &.is-action-mode {
        border-bottom: 4px solid $beewise-yellow;
    }

    &.is-bh4 {
        display: flex;
        flex-direction: row;
        min-height: 48px;
        align-items: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        box-sizing: border-box;

        .btn {
            height: 30px;
        }

        .btn.btn-primary {
            text-transform: uppercase;
            padding: 6px 10px;

            &.is-disabled {
                background-color: $gray-02;
            }
        }

    }

    &-text {
        color: $primary-blue-hover;
        font-size: 13px;
        margin-left: 20px;
    }
}
