@import '~@beewise/theme/build/variables';

$MAP_WIDTH: 400px;

.right-panel-map {
    background: $white;
    width: $MAP_WIDTH;
    display: flex;
    flex-direction: column;
    height: 100%;

    &-wrapper {
        z-index: 2;
        background-color: $purple;
        position: relative;

        &.opened {
            filter: drop-shadow(0 6px 30px $blue-shadow);
        }
    }

    &-icon {
        position: absolute;
        bottom: 10%;
        right: 0;
        padding: 12px 8px;
        background: $white;
        border-radius: 40px 0 0 40px;
        transition: all 0.3s ease-out;

        &.opened {
            right: $MAP_WIDTH;
        }
    }
}
