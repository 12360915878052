@import '~@beewise/theme/build/variables';
.action-panel {

  header {
    display: flex;
    gap: 8px;
    align-items: center;
    width: 100%;

    .fa-xmark {
      display: inline-block;

    }

    .select-field-root {
      margin-left: auto;
    }
  }

  section {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &-cancel-all {
    cursor: pointer;
    color: $gray-60;
    text-decoration: underline;
    margin-left: auto;
  }

}

.right-panel:has(.action-panel) {
  overflow: hidden;
}

.action-list {
  &__empty {
    justify-content: center;
    align-items: center;
    display: flex;
    color: $gray-60;
    font-size: 14px;
    gap: 6px;
  }

  .is-highlighted {
    background-color: $purple-light;
  }
}
