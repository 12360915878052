@import '~@beewise/theme/build/variables';
@import '~@beewise/theme/build/reusables';

.settings {
    $paddingLeft: 161px;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - #{$app-header-height});
    background-color: $grey-8;

    &-content {
        padding: 32px $paddingLeft;
    }

    &-menu {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 22px $paddingLeft 14px $paddingLeft;
        box-sizing: border-box;
        background: $white;

        &-button {
            &.btn {
                border: 0;
                background: transparent;
                box-sizing: border-box;
                text-transform: uppercase;
                position: relative;
                padding: 0;
                line-height: 18px;
            }

            .button-text {
                font-size: 12px;
                color: $grey-1;
                font-weight: 400;
            }

            & + & {
                margin-left: 18px;
            }

            &-active {
                &.btn:before {
                    content: '';
                    position: absolute;
                    top: 30px;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background-color: $brand;
                }
                .button-text {
                    font-weight: 700;
                }
            }
        }
    }

    &-sub {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &-item-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .invite-button {
            color: $blue-old;
        }
    }

    &-item-block {
        @extend .white-box;
        padding: $spacing;
        margin-top: $spacing;
        min-height: 71px;
        height: 60vh;
        overflow: auto;

        &.monitoring-block {
            & > div {
                margin-top: $spacing;
                border-bottom: 1px solid $light-grey;
            }
        }

        > .member-item:first-of-type {
            border-top: 1px solid $light-grey;
        }

        .member-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            min-height: 70px;
            border-bottom: 1px solid $light-grey;

            &-sub {
                color: $grey-6;
                font-size: 12px;
                margin-left: 5px;
            }

            & > div {
                flex: 1;
            }

            & > .member-info {
                display: flex;
            }

            & > .permissions {
                display: flex;
                justify-content: space-around;
                align-items: center;

                .remove-user {
                    background-color: transparent;

                    path {
                        stroke: $dark-grey;
                    }
                }
            }
        }
    }

    &-alerts {
        &-header {
            font-size: 12px;
            color: $grey-2;
        }
        &-content {
            margin-top: 26px;
            display: flex;
            flex-direction: column;
        }

        .ui.checkbox.beewise-checkbox {
            width: 326px;

            input[type='checkbox'] + label {
                font-size: 16px;
                text-transform: capitalize;
            }

            &.disabled {
                order: 99;
                pointer-events: none;
            }
        }
        .beewise-checkbox + .beewise-checkbox {
            margin-top: 18px;
        }
    }

    &-actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: $spacing;
    }

    .settings-item + .settings-item {
        margin-top: $spacing;
    }

    &-profile {
        padding: $spacing;
        display: flex;
        flex-direction: column;

        &-item + &-item {
            margin-top: $spacing;
        }

        &-form {
            @extend .white-box;
            padding: $spacing $spacing;
            margin-top: $spacing;
            display: flex;
            flex-flow: column nowrap;
        }

        &-actions {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: $spacing;

            .reset-password {
                color: $dark-grey;
                cursor: not-allowed;
            }

            .logout {
                color: $bad;
            }
        }

        .text-field-root + .text-field-root {
            margin-top: 50px;
        }
    }
}

.remove-user-modal {
    .modal-content {
        width: 500px;
    }

    .modal-body {
        padding: $spacing;
    }

    .remove-modal-actions {
        margin-top: $spacing;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .btn-primary {
            margin-left: $spacing;
        }
    }
}
