@import '~@beewise/theme/build/variables';

.hive-panel {
    &-header {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%;

      &-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 34px;

        &-left {
          display: flex;
          align-items: center;
          gap: 8px;

          &-icon {
            width: 16px;
            height: 16px;
            cursor: pointer;
          }

          &-hive {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: $black;
          }
        }

        &-right {
          display: flex;
          justify-content: flex-end;

          &-icon {
            padding: 4px;
            width: 24px;
            height: 24px;
            background: $dynamic-partition;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $honey;
          }
        }
      }

      &-bottom {
        display: flex;
        gap: 12px;
      }
    }

    &-block {
      display: flex;
      flex-direction: column;
      gap: 12px;
      width: 100%;
    }
}
