@import '~@beewise/theme/build/variables';

.actions {
  border-radius: 8px;
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
  width: 100%;
  background: $white;
  min-height: 42px;
  max-height: 42px;
  height: 42px;
  box-sizing: border-box;

  &-title {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  svg {
    cursor: pointer;
  }

  &-icons {
    display: flex;
    gap: 8px;
    margin-left: auto;
  }

  .badge {
      font-weight: 500;
  }

  &.history-view {
    justify-content: space-between;
    background: $soft-grey; 

    .info-section {
      display: flex;
      gap: 12px;
    }

    .text-main {
      font-size: 14px;
      font-weight: 600;        
    }

    .text-secondary {
      color: $gray-60;
    }

    .history-view-close-icon {
        cursor: pointer;
    }

    button {
      color: $static-partition;
      background: none;
      border: none;
      cursor: pointer;
      text-decoration: none;
      font-size: 14px;
      font-weight: 400;

      &:hover {
          text-decoration: underline;
      }
    }
  }
}
