@import '~@beewise/theme/build/variables';

.actions-active {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $soft-grey;
    border-radius: 8px;
    min-height: 42px;
    max-height: 42px;
    height: 42px;
    padding: 8px 16px;
    box-sizing: border-box;

    &-title {
      font-weight: 500;
      font-size: 12px;
      color: $gray-60;
    }

    svg {
      cursor: pointer;
      fill: $static-partition;
    }

    &-icons {
      display: flex;
      gap: 16px;
      margin-left: auto;
    }
}
