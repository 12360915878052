@import '~@beewise/theme/build/variables';

.brood-egg {
    background-color: $brood-egg-v2;
    color: $brood-egg-v2;
}

.brood-open {
    background-color: $brood-open-v2;
    color: $brood-open-v2;
}

.brood-capped {
    background-color: $brood-capped-v2;
    color: $brood-capped-v2;
}

.opened-honey {
    background-color: $opened-honey-v2;
    color: $opened-honey-v2;
}

.capped-honey {
    background-color: $capped-honey-v2;
    color: $capped-honey-v2;
}

.pollen {
    background-color: $pollen-v2;
    color: $pollen-v2;
}

.partition {
    color: $dynamic-partition;
    background: $dynamic-partition repeating-linear-gradient(
        -45deg,
        $dynamic-partition,
        $dynamic-partition 2px,
        $black 3px,
    );
    border: .5px solid $black;
    box-sizing: border-box;
}

.static-partition {
    color: $static-partition;
    background: $static-partition repeating-linear-gradient(
        -45deg,
        $static-partition,
        $static-partition 2px,
        $black 3px,
    );
    border: .5px solid $black;
    box-sizing: border-box;
}

.queen-excluder {
    background-color: $queen-excluder;
    color: $queen-excluder;
}

.empty {
    background-color: $white;
    color: transparent;
    box-shadow: inset 0 2px 4px $gray-04;
}

.empty.legend-octagon {
    box-shadow: none;
}

.feeder {
    background-color: $feeder-new;
    color: $feeder-new;
}

.foundation {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;

    &:after {
        height: 80%;
        background-image: radial-gradient($gray-60 0%,$gray-30 77%);
        content: '';
        width: 100%;
        border-radius: 10%;
    }
}

.bees {
    background-color: $orange-7;
    color: $orange-7;
}

.thermal {
    width: 6px;
    height: 0;
    border: 1px solid $feeder-old;
    border-radius: 0;
}

.previous-thermal {
    width: 6px;
    height: 0;
    border: 1px solid $feeder;
    border-radius: 0;
}