@import '~@beewise/theme/build/variables';

.edit-location-card {
    &-header {
        color: $grey-1;
        font-weight: 700;
        font-size: 14px;
        white-space: nowrap;
        padding-bottom: 16px;
    }

    &-actions {
        display: flex;
        padding-top: 12px;
        gap: 12px;
    }
}

