@import '~@beewise/theme/build/variables';

.last-status {
  display: flex;
  gap: 4px;
  align-items: center;
  font-size: 12px;
  color: black;
  svg {
    height: 16px;
    width: 16px;
  }
}
