@import '~@beewise/theme/build/variables';

.small-hive {
  min-height: 160px;

  @media only screen and (min-width: 2560px) {
    min-height: 280px;
  }

  .bee-count-frame {
    position: absolute;
    height: 100%;
    top: 50%;
    left: 50%;
    background: linear-gradient(180deg, #FFFFFF 10%, #FFF586 25%, #FFF586 50%, #FFF586 75%, #FFFFFF 90%);
    transform: translate(-50%, -50%);
    width: auto;

    &.reduced-opacity {
      opacity: .6;
    }
  }

  &-content {
    display: flex;
    flex: 1;
    position: relative;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    &-block {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      &-tooltip-content {
        min-height: 24px;
        flex-grow: 1;
      }
    }

    &-icons {
      display: flex;
      gap: 8px;
    }
  }

  &.reduced-opacity {
    .small-hive-content {
      opacity: 0.5;
    }
  }

  &-name {
    text-align: center;
    text-transform: uppercase;
    position: relative;

    &-dot {
      width: 5px;
      height: 5px;
      background: $static-partition;
      border-radius: 50%;
      position: absolute;
      top: -25%;
      right: -30%;
    }
  }

  &-icon {
    width: 16px;
    height: 16px;

    svg,
    path {
      stroke: $gray-60;
      color: $gray-60;
    }
  }
}