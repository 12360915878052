@import '~@beewise/theme/build/variables';

$frame-photo-height: 440px;

.feeder-frame-placeholder {
    width: 100%;
    height: $frame-photo-height;
    border-radius: 8px;
    border: 8px solid $feeder-new;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 31px;
    box-sizing: border-box;
    padding: 24px;
    background: #b884fa;

    &-rect {
        background: #9b53f8;
        border-radius: 8px;
        font-size: 18px;
        font-weight: 700;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;

        &:nth-of-type(2) {
            text-align: center;
        }
    }
}

.queen-excluder-placeholder {
    width: 100%;
    height: $frame-photo-height;
    border-radius: 8px;
    background-color: #2da391;
    border: 8px solid #2da391;
    box-sizing: border-box;
    display: grid;
    position: relative;
    grid-template-rows: repeat(22, 1fr);
    grid-template-columns: repeat(6, 1fr);
    grid-row-gap: 2px;
    grid-column-gap: 8px;

    &-label {
        font-size: 18px;
        font-weight: 700;
        color: $white;
        background-color: $teal;
        grid-column-start: 3;
        grid-column-end: 5;
        grid-row-start: 11;
        grid-row-end: 13;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    &-empty-cell {
        background-color: $teal;
    }
}

.partition-frame-placeholder {
    width: 100%;
    height: $frame-photo-height;
    border-radius: 8px;
    box-sizing: border-box;
    background-color: $gray;
    padding: 24px;

    &-content {
        width: 100%;
        height: 100%;
        background-color: $gray-08;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &-label {
        font-size: 18px;
        font-weight: 700;
        color: $white;
        text-align: center;
    }
}

.static-partition-frame-placeholder {
    width: 100%;
    height: $frame-photo-height;
    border-radius: 8px;
    box-sizing: border-box;
    background-color: $static-partition;
    padding: 24px;

    &-content {
        width: 100%;
        height: 100%;
        background-color: $static-partition;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &-label {
        font-size: 18px;
        font-weight: 700;
        color: $white;
        text-align: center;
    }
}

.empty-frame-placeholder {
    width: 100%;
    height: $frame-photo-height;
    border: 8px solid $gray-03;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: $gray-02;
    padding: 24px;

    &-content {
        width: 100%;
        height: 100%;
        border-radius: 8px;
        background-color: $gray-03;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &-label {
        font-size: 18px;
        font-weight: 700;
        color: $white;
        text-align: center;
    }
}

.not-available-scan-placeholder {
    width: 100%;
    height: $frame-photo-height;
    box-sizing: border-box;
    padding: 24px;
    background-color: $gray-20;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &-label {
        color: $gray-80;
        font-size: 14px;
        font-weight: 400;
        margin-top: 16px;
        text-align: center;
    }

    .not-available-scan-icon {
        width: 60%;
        height: 60%;
    }
}

.not-available-scan-placeholder-big {
    width: 100%;
    height: $frame-photo-height;
    box-sizing: border-box;
    border-radius: 8px;
    border: 8px solid $gray-01;
    padding: 24px;
    background-color: $gray-02;

    &-content {
        width: 100%;
        height: 100%;
        border-radius: 8px;
        background-color: $grey-7;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
    }

    &-label {
        color: $grey-3;
        font-size: 36px;
        font-weight: 700;
        text-align: center;
    }

    .icon {
        width: 72px;
        height: 72px;
        position: relative;
        margin-bottom: 16px;

        svg {
            width: 72px;
            height: 72px;
            color: $grey-3;
            stroke-width: 1px;
        }

        &:after {
            content: '';
            width: 3px;
            height: 102px;
            background-color: $grey-3;
            transform: rotateZ(45deg);
            position: absolute;
            left: 46%;
            top: -17%;
        }
    }
}

.loading-placeholder {
    width: 100%;
    height: $frame-photo-height;
    box-sizing: border-box;
    border-radius: 8px;
    background-image: url('./frame.jpg');
    background-size: cover;
}

.magnifier-image {
    min-height: $frame-photo-height;
}
