@import '~@beewise/theme/build/variables';

.bhome-item {
  padding: 16px;
  background: $gray-0;
  border-bottom: 1px solid $grey-7;
  cursor: pointer;
  display: flex;
  gap: 12px;
  flex-direction: column;

  .without-title {
    width: auto;
    padding: 4px;
    gap: 4px;
    background: $purple-light;
    border-radius: 4px;
  }

  .low-data {
    background: $pumping-spice-10;

    .bhome-item-block-item-data, .bhome-item-block-item-header {
      color: $pumping-spice;
    }
  }

  &-block {
    display: flex;

    &-item {
      width: 100px;
      display: inline-flex;
      align-items: center;
      gap: 4px;

      &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 4px;
        height: 20px;
        font-weight: 400;
        font-size: 12px;
        color: $black;
      }

      &-data {
        font-weight: 600;
        font-size: 12px;
        color: $black;
      }
    }
  }
}
