@import '~@beewise/theme/build/variables';

.brood-egg {
    background-color: $eggs;
    color: $eggs;
}

.brood-open {
    background-color: $open-brood;
    color: $open-brood;
}

.brood-capped {
    background-color: $capped-brood;
    color: $capped-brood;
}

.opened-honey {
    background-color: $open-honey;
    color: $open-honey;
}

.capped-honey {
    background-color: $capped-honey;
    color: $capped-honey;
}

.pollen {
    background-color: $pollen;
    color: $pollen;
}

.partition {
    color: $dynamic-partition;
    background: $dynamic-partition repeating-linear-gradient(
        -45deg,
        $dynamic-partition,
        $dynamic-partition 2px,
        $black 3px,
    );
    border: .5px solid $black;
    box-sizing: border-box;
}

.static-partition {
    color: $static-partition;
    background: $static-partition repeating-linear-gradient(
        -45deg,
        $static-partition,
        $static-partition 2px,
        $black 3px,
    );
    border: .5px solid $black;
    box-sizing: border-box;
}

.queen-excluder {
    background-color: $queen-excluder;
    color: $queen-excluder;
}

.empty {
    background-color: $white;
    color: transparent;
    box-shadow: inset 0 2px 4px $gray-04;
}

.empty.legend-octagon {
    box-shadow: none;
}

.feeder {
    background-color: $feeder-new;
    color: $feeder-new;
}

.bees {
    background-color: $orange-7;
    color: $orange-7;
}

.legend .legend-octagon.thermal {
    width: 6px;
    height: 0;
    border: 1px solid $feeder-old;
    border-radius: 0;
}

.legend .legend-octagon.previous-thermal {
    width: 6px;
    height: 0;
    border: 1px solid $feeder;
    border-radius: 0;
}
