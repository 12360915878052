@import '~@beewise/theme/build/variables';

$activity-width: 452px;
$header-height: 64px;

.right-panel-header {
    min-height: $header-height;
    box-sizing: border-box;
    display: flex;
    width: $activity-width;
    top: $app-header-height;
    background-color: $white;
    align-items: center;
    padding: 0 24px 0 12px;
    border-bottom: 1px solid $gray-01;
    z-index: 2;

    &-title {
        font-size: 18px;
        font-weight: 600;
        color: $gray-06;
        display: flex;
        line-height: 22px;

        .right-panel-header-location {
            max-width: 340px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .right-panel-header-id {
            margin-left: 8px;
        }

        &.full-title {
            .right-panel-header-location {
                max-width: 135px;
            }
        }

        &.half-title {
            .right-panel-header-location {
                max-width: 240px;
            }
        }

        .right-panel-header-bhome-info {
            display: flex;
            cursor: pointer;

            .right-panel-header-id {
                font-weight: 300;
            }

            &.is-active {
                cursor: default;

                .right-panel-header-location {
                    color: $yellow-dark;
                }
            }

            &:not(.is-active):hover {
                color: $blue-old;
            }
        }

        .right-panel-header-hive-info {
            cursor: pointer;

            &.is-active {
                cursor: default;
                color: $yellow-dark;
            }

            &:not(.is-active):hover {
                color: $blue-old;
            }
        }

        .right-panel-header-frame-info.is-active {
            cursor: default;
            color: $yellow-dark;
            &:active {
                opacity: 0.8
            }
        }
    }

    &-separator {
        margin-left: 10px;
        margin-right: 10px;
        font-weight: 400;
    }

    &-live-stream {
        font-size: 16px;
        color: $gray-08;
        margin-left: auto;
        cursor: pointer;
        transform: scaleX(-1);

        &:hover {
            border-radius: 4px;
            background-color: $gray-015;
            padding: 4px;
            cursor: pointer;
            margin: -4px;
        }
    }

    &-buttons-wrapper {
        margin-left: auto;
    }

    &-close {
        font-size: 18px;
        color: $gray-08;
        cursor: pointer;
        margin-left: 16px;

        &:hover {
            border-radius: 4px;
            background-color: $gray-015;
            padding: 4px 6px;
            cursor: pointer;
            margin: -4px -6px -4px 10px;
        }
    }
}

.kinesis-video {
    video {
        width: 100%;
    }

    .restart {
        position: absolute;
        top: 22px;
        right: 100px;
    }
}
