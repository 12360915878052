@import '~@beewise/theme/build/variables';
@import '~@beewise/theme/build/reusables';

.app-header {
    background-color: $black;
    height: $app-header-height;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 20px 0 20px;
    box-sizing: border-box;
    position: relative;

    .sidebar-toggler {
        position: absolute;
        top: 50%;
        left: 28px;
        transform: translateY(-50%);
        cursor: pointer;

        path {
            fill: $grey-5;
        }

        &-hidden {
            svg {
                width: 19px;
            }
            path {
                stroke: $grey-5;
            }
        }
    }

    .logo-container {
        padding: 10px 0 10px;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        align-items: center;

        .app-env {
            margin-left: 12px;
            border-radius: 4px;
            padding: 4px 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 600;
            font-size: 11px;
            color: $black;

            &.beta {
                background-color: $grey-7;
            }
        }
    }

    .nav-list {
        margin-left: 56px;
        display: flex;
        gap: 45px;


        a, span {
            color: $white;
            text-transform: capitalize;
            text-decoration: none;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;

            &:hover {
                color: $brand;
            }

            &.active {
                color: $brand;
            }
            &.disabled {
                color: $grey-3;
                cursor: default;
            }
        }

        &-disabled {
            pointer-events: none;
        }
    }

    .main-menu {
        margin-left: auto;
        display: flex;
        align-items: center;

        &-dropdown{
            position: relative;
            z-index: 1001;
            min-width: 160px;
            width: 100%;

            .select-field__control {
                background-color: $black;
                border: none;
                color: $white;

                .select-field__single-value {
                    color: $white;
                    text-overflow: ellipsis;
                }

                .select-field__indicators:before {
                    border-top: 4px solid $white;
                }
            }
        }

        .notifications-bell {
            position: relative;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            background-color: transparent;
            margin-right: 16px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: background-color 0.3s ease-in-out;

            .icon {
                svg {
                    width: 22px;
                    height: 22px;
                    fill: $white;
                }
            }

            .red-dot {
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: red;
                position: absolute;
                top: 0;
                right: 0;
                transform: translate(-35%, 55%);
                border: 1px solid $black;
            }

            &-active {
                background-color: $white;

                .icon {
                    svg {
                        fill: $black;
                    }
                }

                .red-dot {
                    border: 1px solid $white;
                }
            }
        }

        .ui.selection.dropdown.beewise-select.select-operation-mode {
            margin-right: 12px;
            min-width: 160px;
            z-index: 103;
        }

        .user-white {
            path {
                stroke: $white;
            }
        }

        button.icon {
            padding: 0;
            background-color: transparent;
        }
    }
}
