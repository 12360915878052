@import '~@beewise/theme/build/variables';

.regions {
  &-header {
    color: $text-primary;
    display: flex;

    &-text {
      display: flex;
      flex-direction: column;
    }
    .regions-list-item-default {
      align-self: flex-end;
      .regions-list-item-strength {
        width: auto;
      }
    }
  }

  h3 {
    margin-top: 8px;
  }

  &-add.btn {
    margin-top: 32px;
    background: transparent;
    color: $primary-blue;
  }

  &-list {
    display: flex;
    flex-direction: column;

    &-item {
      display: flex;
      margin-top: 32px;
      align-items: center;

      &-default {
        margin-top: 0;
      }

      &-delete {
        margin-left: 32px;
        font-size: 24px;
        cursor: pointer;
        color: $text-secondary;
      }

      &-strength.btn {
        margin-left: 32px;
        width: 170px;
      }

      .hive-strength-checkmark {
        margin-right: 8px;
      }

      .text-field-root {
        width: 310px;
      }

      .select-field-root {
        margin-left: 32px;
        width: 310px;
      }

      .text-field-root, .select-field-root {
        .text-field-label.text-field--active, .select-field-label.select-field--active {
          background: $grey-8;
        }
      }

      .text-field-label--item {
        overflow: visible;
      }

      .select-field-root .select-field__control {
        background: transparent;
      }
    }
  }
}

.region-list-modal {
  &.modal-wrapper .modal-content {
    width: 500px;
  }

  h3 {
    font-weight: 500;
  }

  .strength-block {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .strength-block-line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
    gap: 16px;
  }

  .strength-block-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex: 1;

    .text-field-root {
      width: 50px;
      margin-left: 12px;
    }
  }

  .strength-block-item + .strength-block-item {
    justify-content: flex-end;
  }

  .strength-block-text {
    margin-top: 24px;
  }

  .region-list-modal-buttons {
    display: flex;

    .primary-blue {
      margin-left: 12px;
    }
  }
}
