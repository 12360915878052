@import '~@beewise/theme/build/variables';

.policies-widget-wrapper {
  .beewise-preloader {
    transform: scale(50%);
  }

  .policies-widget {
    flex-direction: column;

    &-content {
      margin-top: 12px;
    }

    &-item {
      display: flex;
      align-items: center;

      &-text {
        margin-right: 24px;
        white-space: nowrap;
        min-width: 72px;
      }

      &-numbers {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-left: 24px;
        min-width: 48px;

        &-amount {
          font-weight: 600;
          font-size: 16px;
        }

        &-percent {
          margin-left: 4px;
        }
      }
    }

    .policies-widget-item + .policies-widget-item {
      margin-top: 12px;
    }

    .progress-container {
      width: 100%;
    }
  }
}
