@import '~@beewise/theme/build/variables';

.hive-block {
    outline: none;

    &-indicator {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 12px;

        &-line {
            width: 142px;
            height: 2px;
            background-color: $gray-02;
        }

        &-icon {
            margin-left: 4px;
            margin-right: 4px;
            font-size: 18px;
            cursor: pointer;

            &.square {
                color: $red;
            }

            &.lock {
                color: $gray-09;

                &:hover {
                    border-radius: 4px;
                    background-color: $gray-015;
                    padding: 4px;
                    margin: -4px 0;
                }
            }

            &-disabled {
                cursor: default;

                &.lock {
                    &:hover {
                        background-color: transparent;
                    }
                }
            }
        }
    }

    &-frames {
        display: flex;
        margin-top: 80px;
        padding: 0 16px;
        position: relative;

        .hive-block-frame-item-wrapper {
            width: 100%;
            position: relative;

            &-status {
                position: absolute;
                display: flex;
                flex-direction: column;
                transform: translateY(-100%);
                align-items: center;
                left: 1px;
                top: -4px;

                .fa-droplet {
                    margin-left: 1px;
                }
            }

            .hive-block-frame-item {
                height: 128px;
                display: flex;
                flex-direction: column-reverse;
                cursor: pointer;
                background-color: $off-white;
                position: relative;
                margin: 0 3px;

                @import 'src/components/reusables/Hive/FrameColors';

                &.is-inspected {
                    outline: 2px solid $beewise-yellow;
                }

                &.canDrag {
                    cursor: grab;
                }

                &.isDragging {
                    cursor: grabbing;
                }

                &.isDisabled {
                    cursor: not-allowed;
                }
            }

            .hive-block-frame-checkbox.ui.fitted.checkbox.beewise-checkbox {
                min-height: unset;
                min-width: unset;
                line-height: unset;
                width: 12px;
                height: 10px;
                margin: 2px 0;
            }

            .ui.checkbox.beewise-checkbox input[type='checkbox'] {
                width: 10px;
                height: 10px;
            }

            .ui.checkbox.beewise-checkbox label:before {
                width: 10px;
                height: 10px;
                background-color: unset;
                border: 1px solid $gray-04;
                border-radius: 2px;
            }

            .ui.checkbox.beewise-checkbox input[type='checkbox']:focus ~ label:before {
                background-color: unset;
                border: 1px solid $gray-04;
                border-radius: 2px;
            }

            .ui.checkbox.beewise-checkbox input[type='checkbox']:checked ~ label:before {
                background-color: $beewise-yellow;
                border: 1px solid $beewise-yellow;
                border-radius: 2px;
            }

            .ui.checkbox.beewise-checkbox input[type='checkbox']:checked ~ label:after {
                width: 12px;
                height: 12px;
                font-size: 6px;
                line-height: 12px;
                color: $white;
            }

            .hive-block-frame-exclamation-square {
                width: 10px;
                height: 10px;
                color: $red;
                cursor: pointer;
            }

            .hive-block-frame-alert-icon {
                font-size: 12px;
                color: #cd1c00;
                pointer-events: none;
            }

            .hive-block-frame-status-icon {
                font-size: 12px;
                color: $gray-04;

                &.with-checkbox {
                    top: -44px;
                }

                &.alerted {
                    path {
                        fill: $bad;
                    }
                }

                &.alerted + .hive-block-frame-status-icon {
                    margin-top: 2px;
                }
            }

            .frame-arrow {
                position: absolute;
                color: $gray-04;
                bottom: -17px;
                left: 1px;
                background: white;
                z-index: 1;
                border: 1px solid $gray-04;
                width: 10px;
                height: 10px;
                transform: rotate(45deg);
                border-bottom: 0;
                border-right: 0;
            }
        }

        &.inspected-frame-shown {
            &:after {
                content: '';
                position: absolute;
                width: 418px;
                height: 1px;
                background-color: $gray-04;
                bottom: -12px;
            }
        }

        &.indicator-shown {
            margin-top: 50px;
        }

        &.is-moving-frames {
            margin-top: 45px;
        }
    }

    &-inspected-frame {
        margin-top: 12px;
        padding: 16px;

        &-bh4 {
            margin-top: 0;
        }

        &-header {
            display: flex;
            line-height: 16px;
            padding: 0 8px 0 0;

            &-last-scanned {
                font-size: 12px;
                color: $gray-09;
            }

            .show-annotation-icon {
                margin-left: auto;
                font-size: 18px;
                color: #fdbc0c;
                cursor: pointer;

                &:hover {
                    padding: 4px;
                    border-radius: 4px;
                    background-color: $gray-01;
                    margin-right: -4px;
                    margin-top: -4px;
                    margin-bottom: -4px;
                }

                &.is-active {
                    padding: 4px;
                    margin-top: -4px;
                    margin-right: -4px;
                    margin-bottom: -4px;
                    background-color: $beewise-yellow;
                    color: white;
                    border-radius: 4px;
                }
            }
        }

        &-scan-details {
            color: $gray-09;
            display: flex;
            flex-direction: column;
            gap: 4px;
            padding: 0 0 24px 0;

            .scan-details {
                &-header {
                    font-size: 16px;
                    font-weight: 500;
                    padding: 0 0 24px 0;
                }

                &-line {
                    display: flex;
                    flex-direction: row;
                    gap: 3px;
                    font-size: 14px;

                    p:first-child {
                        font-weight: 500;
                    }
                }
            }
        }

        &-scans {
            display: flex;
            flex-direction: column;
            gap: 16px;

            .preloader {
                .icon {
                    transform: scale(0.4);
                }
            }

            .section-header {
                color: $gray-09;
                padding: 8px 0;
                font-size: 16px;
                font-weight: 500;
            }
        }

        &-scan-a {
            position: relative;
            cursor: pointer;

            .magnifier-image {
                max-width: 420px;
                height: 136px;
                min-height: 136px;
                border-radius: 8px;
            }

            .magnifier {
                display: block;
            }

            &.full-screen {
                outline: 2px solid $beewise-yellow;
            }
        }

        &-scan-b {
            position: relative;
            cursor: pointer;

            .magnifier-image {
                max-width: 420px;
                height: 136px;
                min-height: 136px;
                border-radius: 8px;
            }

            .magnifier {
                display: block;
            }

            &.full-screen {
                outline: 2px solid $beewise-yellow;
            }
        }

        &-scan-side-label {
            position: absolute;
            font-size: 16px;
            font-weight: 600;
            color: $white;
            top: 16px;
            left: 16px;
            cursor: pointer;
            z-index: 1;
        }
    }

    &-entrances {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
        width: 100%;
        margin: 16px 0 48px;

        .hive-block-entrance {
            cursor: pointer;
            height: 20px;
            display: flex;
            align-items: center;

            .hive-block-opened {
                color: $gray-06;
                font-size: 20px;
            }

            .hive-block-closed {
                width: 20px;
                height: 4px;
                background-color: $red;
                border-radius: 4px;
            }
        }
        .hive-block-entrance + .hive-block-entrance {
            margin-left: 22px;
        }
    }
}

.padding-line {
    margin: 0 4px;
}
