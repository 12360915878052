@import '~@beewise/theme/build/variables';

.progress-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 20px;
    gap: 10px;
    background: $secondary-orange;
    border-bottom: 1px solid rgba(0, 0, 0, .12);

    button {
        font-size: 13px;
        text-decoration-line: underline;
        color: $primary-blue;
        background: none;
    }
}
