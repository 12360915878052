.yard-group-marker {
  background: rgba(255, 255, 255, .6);
  border-radius: 52px;
  width: 36px;
  height: 36px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, .25);
  z-index: 1;
  transition: all 0.1s ease-out;
  backdrop-filter: blur(2px);

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
  }
}
