@import '~@beewise/theme/build/variables';

.visible {
  margin-right: 8px;
  visibility: visible;
  height: 16px;
  width: 16px;
}

.hidden {
  visibility: hidden;
  height: 0;
  width: 0;
}

.card {
  border-radius: 12px;
  background-color: $white;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
  flex: 1;
  gap: 10px;
  cursor: pointer;
  transition: all .1s ease-in-out;
  border: 1px solid transparent;

  &-header {
    display: flex;
    min-height: 24px;
  }

  &:hover, &.hovered, &.selected, &.inspected  {
    box-shadow: 0 6px 30px 0 $blue-shadow;
  }

  &:hover, &.select-mode {
    .checkbox-icon, .checkbox-icon_unselected {
      @extend .visible;
    }
  }

  .checkbox-icon {
      @extend .hidden;
      transition: all .1s ease-out;

    &_unselected {
      @extend .hidden;
      transition: all .1s ease-out;
    }

    &:hover, &.shift-hovered {
      @extend .visible;
        .checkbox-icon_unselected {
          @extend .hidden;
          display: none;
          transition: all 0.1s ease-out;
        }

        .checkbox-icon_selected:not(.selected) {
            @extend .visible;
            color: $grey-6;
        }
    }
  }

  .checkbox-icon_selected:not(.selected) {
    @extend .hidden;
  }

  .checkbox-icon.selected, .checkbox-icon_selected.selected {
    @extend .visible;
    color: $primary-blue !important;
  }

  &.inspected {
    border: 1px solid $primary-blue;
  }
}
