@import '~@beewise/theme/build/variables';
@import '~@beewise/theme/build/reusables';
@import 'src/variables';

$widgets-row-height: 134px;
$dashboard-row-gap: 12px;

.dashboard {
  padding: $dashboard-padding;
  background-color: $grey-8;
  width: 100%;
  box-sizing: border-box;
  height: calc(100vh - #{$app-header-height});
  overflow: auto;
  display: grid;
  grid-template-rows: $widgets-row-height auto;
  grid-row-gap: $dashboard-row-gap;

  .feed-grid-actions + .searchable-grid-container {
    height: 90%;
  }

  .secondary-text {
    color: $text-secondary;
    font-size: 14px;
  }

  &-widgets {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 12px;
    grid-row-gap: $dashboard-row-gap;
    grid-row: 1 / 2;
    grid-column: 1 / 13;

    .widget {
      box-shadow: 0 4px 17px 0 rgba(0, 0, 0, .05);
      box-sizing: border-box;
      padding: 16px;
      display: flex;
      border-radius: 8px;
      background: $white;
      height: 100%;
    }

    .total-beehomes-widget-wrapper {
      grid-row: 1 / 2;
      grid-column: 1 / 3;
    }
    .online-widget-wrapper {
      grid-row: 1 / 2;
      grid-column: 3 / 6;
    }
    .policies-widget-wrapper {
      grid-row: 1 / 2;
      grid-column: 6 / 10;
    }
    .performance-widget-wrapper {
      grid-row: 1 / 2;
      grid-column: 10 / 13;
    }
    .progress-container {
      height: 14px;
      border-radius: 8px;

      .progress-filler {
        border-radius: 8px;
      }
    }
  }

  &-grid {
    grid-row: 2 / 3;
    grid-column: 1 / 13;
    height: calc(100vh - #{$app-header-height} - #{$widgets-row-height} - #{$dashboard-padding * 2} - #{$dashboard-row-gap} - #{$dashboard-grid-padding * 2});
  }
}
