@import '~@beewise/theme/build/variables';

.visible {
  visibility: visible;
  height: 14px;
  width: 14px;
}

.hidden {
  visibility: hidden;
  height: 0;
  width: 0;
}

.hives-v2 {
  @import './SmallHive/StationFrame/FrameColors.scss';

  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  gap: 16px;

  .tabs {
    margin-left: auto;
  }

  .hive-station-items {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-right: 16px;
  }

  .hive-stations-header {
    display: flex;
    gap: 8px;
  }

  .hive-stations-content {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .checkbox-wrapper {
    display: flex;
    border-radius: 20px;
    padding: 8px 12px;
    background: $white;
    align-items: center;

    .checkbox-icon {
      transition: all .1s ease-out;
      height: 14px;
      width: 14px;

      &_unselected {
        transition: all .1s ease-out;
        height: 14px;
        width: 14px;
      }

      &:hover {
        @extend .visible;
          .checkbox-icon_unselected {
            @extend .hidden;
            display: none;
            transition: all 0.1s ease-out;
          }

          .checkbox-icon_selected:not(.selected) {
              @extend .visible;
              color: $grey-6;
          }
      }
    }

    .checkbox-label {
      font-size: 14px;
      padding-left: 6px;
    }
  }

  .checkbox-icon_selected:not(.selected) {
    @extend .hidden;
  }

  .checkbox-icon.selected, .checkbox-icon_selected.selected {
    color: $primary-blue !important;
  }
}
