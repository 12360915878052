@import '~@beewise/theme/build/variables';

$large-width: 34px;
$large-height: 38px;

.marker {
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    filter: drop-shadow(0 4px 8px rgba(0, 0, 0, .25));
    transition: all 0.1s ease-out;
    cursor: pointer;

    &-wrapper {
        position: relative;
        transform: translate(-50%, -50%);
        justify-content: flex-start;
        align-items: center;
        box-sizing: border-box;


        &:has(.bhome-unassigned), &:has(.bhome-assigned) {
            z-index: 1;
        }

        &:hover {
            z-index: 2;
        }
    }

    &:not(.empty-location) {
        z-index: 1;
    }

    &-value {
        font-weight: 600;
        font-size: 12px;
        color: $grey-1;
        position: absolute;
    }

    .hex {
        position: relative;
    }

    .marker-alert {
        position: absolute;
        width: 28px;
        height: 28px;
        top: -4px;
        right: 0;
        transform: translateX(20%);
        z-index: 1;
        padding: 0;
        color: $red;

        &:hover {
            background-color: transparent;
        }
    }

    .tooltip-wrapper {
        .alert-icon {
            width: 22px;
            height: 22px;
        }
    }

    .secondary-marker-alert {
        transform: translateX(70%);
        z-index: unset;
    }

    &-large {
        svg {
            width: $large-width;
            height: $large-height;
        }
    }

    &.bhome-unassigned {
        svg {
            path:first-of-type {
                stroke: $gray-30;
                stroke-width: 2;
                stroke-opacity: .8;
            }

            path:nth-of-type(2n) {
                fill: $gray-90;
                stroke: none;
                fill-opacity: .8;
            }
        }
        .marker-value {
            font-size: 10px;
            color: $white;
            top: 25%;
        }

    }

    &.bhome-assigned {
        opacity: 80%;

        svg {
            path:first-of-type {
                fill: $black;
                stroke: $white;
                stroke-width: 2;
                stroke-opacity: .7;
            }

            path:nth-of-type(2n) {
                fill:$white;
                stroke:$black;
                stroke-opacity: 1;
                fill-opacity: .7;
            }
        }

        .marker-value {
            color: $white;
            top: 25%;
        }
    }

    &.marker-large.bhome-unassigned {
        svg {
            width: $large-width;
            height: $large-height;
        }

        .marker-value {
            top: 30%;
        }
    }

    &.is-selected {
        svg,  svg:hover {
            path:first-of-type {
                fill: $black;
                stroke: $white;
                stroke-width: 2;
            }

            path:nth-of-type(2n) {
                fill:$brand;
                stroke:$black;
                stroke-opacity: 1;
                fill-opacity: 1;
            }
        }

        .marker-value {
            top: 25%;
        }

    }

    &.is-selected.bhome-assigned {
        opacity: 100%;
    }
}

@keyframes recentlyMovedBhomeSize {
    from {
        transform: scale(1.15);
    }
    to {
        transform: scale(1);
    }
}

@keyframes recentlyMovedBhomeColor {
    from {
        fill: $brand;
    }
    to {
        fill: $white;
    }
}

.marker-animated {
    & > .hex:first-child {
        animation: recentlyMovedBhomeSize .7s ease-in-out 0s;
        svg,
        path:last-child {
            animation: recentlyMovedBhomeColor .7s ease-in-out 0s;
        }
    }
}

.bhome-tooltip {
    font-size: 12px!important;
}
