@import '~@beewise/theme/build/variables';

.activity-failure-popover {
    position: absolute;
    z-index: 1000;
    width: 352px;
    right: 16px;
    box-sizing: border-box;
    border: 1px solid $gray-005;
    border-radius: 4px;
    box-shadow: 0 0 16px $gray-04;
    opacity: 1;
    top: 48px;

    &-header {
        background-color: $gray-01;
        padding: 4px 8px;
        display: flex;
        height: 32px;
        align-items: center;
        box-sizing: border-box;
    }

    &-title {
        font-size: 14px;
        color: $gray-09;
    }

    &-xmark {
        font-size: 18px;
        color: $gray-07;
        margin-left: auto;
        cursor: pointer;
    }

    &-body {
        background-color: $white;
        padding: 12px 8px;
        display: flex;
        box-sizing: border-box;
    }

    &-alert-triangle {
        font-size: 18px;
        color: $beewise-yellow;
        margin-top: -2px;
    }

    &-content {
        font-size: 12px;
        color: $gray-09;
        margin-left: 8px;
        line-height: 16px;
    }

    &:before {
        content: '';
        position: absolute;
        top: -9px;
        right: 46px;
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        border-bottom: 9px solid $white;
    }
}
