@import '~@beewise/theme/build/variables';

.total-beehomes-widget-wrapper {
  .beewise-preloader {
    transform: scale(50%);
  }

  .total-beehomes-widget {
    align-items: center;
    gap: 16px;

    .bhome-icon {
      background: $grey-8;
      border-radius: 8px;
      padding: 8px;
      box-sizing: border-box;
    }

    &-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 16px;
    }

    &-beehome-amount {
      color: $text-primary;
      font-weight: 600;
      font-size: 28px;
    }
  }
}


