@import '~@beewise/theme/build/variables';

.performance-widget-wrapper {

  .beewise-preloader {
    transform: scale(50%);
  }

  .performance-widget {
    flex-direction: column;
    gap: 12px;

    &-item, &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-item-amount {
      font-size: 16px;
      font-weight: 600;
    }

    &-item:not(:last-child) {
      padding-bottom: 6px;
      border-bottom: 1px solid $gray-40;
    }
    &-item:not(:first-child) {
      padding-top: 6px;
    }

    .fa-circle-info {
      margin-right: 8px;
      margin-left: auto;
    }
  }

  .light-text {
    color: $gray-50;
  }
}
