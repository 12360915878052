@import '~@beewise/theme/build/variables';

.gm-style-iw-c,
.gm-style-iw-c:after,
.gm-style-iw-t:after,
.gm-bundled-control-on-bottom {
    display: none;
}
// remove default google maps blue border on focus
.gm-style iframe + div { 
    border: none !important; 
}

.map-wrapper {
    height: auto;
    position: relative;
    padding: 16px;
    background: $purple;
    flex: 1 1 0;

    #map {
        border-radius: 8px;
    }

    *:focus {
        outline: none !important;
        border: none !important;
    }
}