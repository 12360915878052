@import '~@beewise/theme/build/variables';

#app #view main.main-bhome {
  flex-direction: row;

  .large-title {
    font-weight: 700;
    font-size: 16px;
  }
  .subtitle {
    font-size: 12px;
    font-weight: 400;
    color: $gray-60;
  }

  .bold {
    font-weight: 700;
  }

  b {
    font-weight: 600;
  }

  h3 {
    font-size: 16px;
  }

  h4 {
    font-size: 14px;
    color: $gray-80;
    display: flex;
    align-items: center;
  }

  .row {
    display: flex;
    align-items: center;
  }
}
