@import '~@beewise/theme';
@import '~react-phone-input-2/lib/material.css';

@import '~@beewise/icon/build/lib/Icon';
@import '~@beewise/button/build/lib/Button';
@import '~@beewise/chart/build/lib/Chart';
@import '~@beewise/menu/build/lib/Menu';
@import '~@beewise/modal/build/lib/Modal';
@import '~@beewise/toast/build/lib/Toast';
@import '~@beewise/tooltip/build/lib/Tooltip';
@import '~@beewise/beehome-status/build/lib/BeehomeStatus';

$thin-sidebar-width: 72px;
$wide-sidebar-width: 238px;

#app {
    font-size: 14px;
    height: 100vh;

    #view {
        display: grid;
        grid-template-areas:
            'beehome-header aside'
            'main aside';
        grid-template-columns: 1fr auto;
        position: relative;
        height: calc(100vh - #{$app-header-height});
        overflow: hidden;

        .beehome-header {
            grid-area: beehome-header;
        }

        .main {
            display: flex;
            flex-direction: column;
            height: calc(100vh - #{$app-header-height});

            &-content {
                display: flex;
                height: 100%;
            }
        }
    }
}

.Toastify__toast.beewise-toast.action-toast {
    margin-top: 48px;

    .beewise-toast-interact {
        display: none;
    }
}

@keyframes showActivityToast {
    from,
    60%,
    75%,
    90%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
        opacity: 0;
        transform: translate3d(0, -3000px, 0) scaleY(3);
    }
    60% {
        opacity: 1;
        transform: translate3d(0, 25px, 0) scaleY(0.9);
    }

    75% {
        transform: translate3d(0, -10px, 0) scaleY(0.95);
    }

    90% {
        transform: translate3d(0, 5px, 0) scaleY(0.985);
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}

.showActivityToast {
    animation-name: showActivityToast;
}

@keyframes flipToastToActivity {
    from {
        transform: scale(1);
        opacity: 1;
    }

    20% {
        transform: translateX(0) scale(0.7);
        opacity: 0.7;
    }

    90% {
        opacity: 0.7;
    }

    to {
        transform: translateX(200%) scale(0.7);
        opacity: 0.3;
    }
}

.flipToastToActivity {
    animation-name: flipToastToActivity;
}

.app {
    overflow: hidden;

    h2 {
        font-size: 24px;
    }

    button {
        display: flex;
        justify-content: center;
        font-size: 13px;
        font-weight: 500;
    }

    .pushable {
        background-color: $grey;
    }

    .ui.vertical.left.sidebar.menu {
        background-color: $off-white;
        box-sizing: border-box;
        display: flex;
    }
    .ui.vertical.left.very.thin.visible.sidebar.menu {
        width: $thin-sidebar-width;
    }
    .ui.vertical.left.wide.visible.sidebar.menu {
        width: $wide-sidebar-width;
    }
    .ui.visible[class*='very thin'].left.sidebar ~ .pusher {
        transform: translate3d($thin-sidebar-width, 0, 0);
        width: calc(100vw - #{$thin-sidebar-width});
        transition: none;
    }
    .ui.visible.wide.left.sidebar ~ .pusher {
        transform: translate3d($wide-sidebar-width, 0, 0);
        width: calc(100vw - #{$wide-sidebar-width});
        transition: none;
    }
}

.preloader-wrapper {
    position: relative;

    .preloader {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1000;
        background-color: rgba(0, 0, 0, 0.5);
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .spinner {
            path {
                stroke: $brand;
            }
        }
    }
}

@keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
}

.select-field__menu {
    animation: fadeIn 0.2s ease-in-out;
}

.beewise-toast-container.Toastify__toast-container, .beehome-page-toast-container, .workspace-page-toast-container {
    width: fit-content;
    min-width: fit-content;

    .beewise-toast.toast-success {
        align-items: center;
        justify-content: center;
        padding: 8px 12px;
        background: $open-brood;
        border-radius: 4px;
        font-family: 'Poppins', serif;
        font-size: 14px;
        font-weight: 500;
        text-align: center;

        .beewise-toast-actions {
            display: none;
        }
    }
}

.beehome-page-toast-container {
    left: 30% !important;
    transform: translateX(-30%) !important;
}

body ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
}

body ::-webkit-scrollbar-thumb  {
    background-color: $grey-6;
    border-radius: 2px;
    width: 4px;
}

body ::-webkit-scrollbar-track {
    background-color: transparent;
}

.thumb-vertical {
    background-color: $grey-6;
    border-radius: 2px;
    width: 4px !important;
    z-index: 10;
}

.thumb-vertical-list {
    background-color: $grey-6;
    border-radius: 2px;
    width: 4px;
    left: -8px;
}