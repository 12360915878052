.bee-count {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;

  &:after {
    height: var(--height);
    background-image: var(--backgroundImage);
    content: '';
    width: 100%;
    border-radius: 10%;
  }
}
