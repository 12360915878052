@import '~@beewise/theme/build/variables';

.history-pane.activities-block {
    padding-bottom: 24px;
}

.queue-pane {
    padding-bottom: 24px;

    &-estimation {
        margin-top: 8px;
        color: $gray-06;
        font-size: 10px;
        line-height: 14px;

        .clock-icon {
            font-size: 12px;
            margin-left: 10px;
            margin-right: 8px;
        }
    }
}

.pagination-button {
    font-size: 10px;
    color: $gray;
    padding: 2px 4px;
    border: 1px solid $yellow-dark;
    width: fit-content;
    border-radius: 4px;
    margin: auto;
    cursor: pointer;

    &:hover {
        background-color: $gray-015;
    }

    &-arrow {
        font-size: 12px;
        margin-right: 4px;
    }
}

.populated-areas-warning {
    font-size: 10px;
    color: $gray-06;
    line-height: 14px;
    display: flex;
    margin-top: 20px;
    text-align: left;
    padding: 0 8px;
    margin-left: 2px;

    .circle-info {
        width: 12px;
        height: 12px;
        margin-right: 10px;
    }
}
