@import '~@beewise/theme/build/variables';

$thumb-shadow-color: #A4A8B7B2;
$thumb-background-color: #D6D6D6;
$tooltip-font-color: #111111;

.hives-history-slider {
  padding-top: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;

  .slider-container {
    position: relative;
    width: 100%;

    .slider-markers {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;

      .slider-marker {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 2px;
        background-color: white;
        transform: translateX(-50%);
      }
    }
  }

  input[type="range"] {
    width: 100%;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: linear-gradient(
      to right,
      #E5E5E5 var(--slider-value, 0%),
      #85BCFF var(--slider-value, 0%),
      #BBD8FA 100%
    );
    cursor: pointer;
    margin: 0;
    transform: rotate(180deg);
    height: 8px;
    border-radius: 5px;

    &::-webkit-slider-runnable-track {
      height: 8px;
      background: transparent;
      border-radius: 5px;
    }

    &::-moz-range-track {
      height: 8px;
      background: transparent;
      border-radius: 5px;
    }

    &::-ms-track {
      height: 8px;
      background: transparent;
      border-radius: 5px;
    }

    &::-webkit-slider-thumb {
      appearance: none;
      -webkit-appearance: none;
      height: 20px;
      width: 10px;
      background: white;
      box-shadow: 0 0 6px 0 $thumb-shadow-color;
      margin-top: -4px;
    }

    &::-moz-range-thumb {
      appearance: none;
      height: 20px;
      width: 10px;
      background: white;
      box-shadow: 0 0 6px 0 $thumb-shadow-color;
      margin-top: -4px;
    }

    &::-ms-thumb {
      appearance: none;
      height: 20px;
      width: 10px;
      background: white;
      box-shadow: 0 0 6px 0 $thumb-shadow-color;
      margin-top: -4px;
    }

    &:disabled::-webkit-slider-thumb {
      background: $thumb-background-color;
    }

    &:disabled::-moz-range-thumb {
      background: $thumb-background-color;
    }

    &:disabled::-ms-thumb {
      background: $thumb-background-color;
    }
  }

  .slider-tooltip {
    font-size: 14px;
    font-weight: 500;
    position: absolute;
    top: 25px;
    white-space: nowrap;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 6px rgba(0, 0, 0, .1);
    color: $tooltip-font-color;
    transform: translateX(-50%);
    padding: 8px 12px;
  }

  .slider-tooltip::before {
    content: '';
    position: absolute;
    top: -5px; 
    left: 50%;
    transform: translateX(-50%);
    border-width: 0 6px 6px 6px; 
    border-style: solid;
    border-color: transparent transparent white transparent; 
  }

  .slider-value-display {
    display: flex;
    justify-content: center;
    width: 8%;
    font-size: 12px;
    font-weight: 400;
    color: $gray-80;
  }
}
