@import '~@beewise/theme/build/variables';
$HIVE_PANEL_WIDTH: 450px;

.right-panel {
  background: $white;
  width: $HIVE_PANEL_WIDTH;
  height: 100%;
  padding: 24px 20px;
  box-shadow: 3px 0 16px 4px rgba(0, 0, 0, .05), 3px 0 16px 4px rgba(0, 0, 0, .05);
  overflow-y: scroll;

  &-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: flex-start;
    width: 100%;
    height: 100%;

    > :last-child {
      padding-bottom: 60px;
    }
  }
}


