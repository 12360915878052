@import '~@beewise/theme/build/variables';
.badge {
  border-radius: 18px;
  font-size: 12px;
  align-items: center;
  display: flex;
  gap: 2px;
  max-width: fit-content;
  justify-content: center;

  svg {
    width: 16px;
    height: 16px;
  }

  &.medium {
    padding: 4px 12px;
  }

  &.small {
    padding: 4px 6px;
  }

  &.outlined {
    &.badge-default {
      border: 1px solid $primary-blue;
      color: $primary-blue;
    }

    &.badge-warn {
      border: 1px solid $beewise-yellow;
      color: $beewise-yellow;
    }
  }

  &-info {
    background-color: $primary-light-blue;
    color: $primary-blue;
  }

  &-warn {
    background-color: $orange-8;
    color:  $beewise-yellow;
  }

  &-error {
    background: $pale-pink;
    color: $fiery-rose;
  }

   &-default {
    background-color: $purple-light;
    color: $gray-80;
  }

  &-default-light {
    background-color: $purple;
    color: $gray-80;
  }

  &-square {
    border-radius: 4px;
    padding: 8px 12px;
  }

  &:hover {
    &.small {
      .icon-cancel {
        visibility: visible;
        width: 10px;
        height: 10px;
      }
    }
    .icon-cancel {
      visibility: visible;
      width: 16px;
      height: 16px;
    }
  }

  .icon-cancel {
    width: 0;
    height: 0;
    visibility: hidden;
    transition: all .1s ease-in-out;
  }
}
