@import '~@beewise/theme/build/variables';

.empty-pane-placeholder {
    padding-bottom: 16px;

    &-body {
        width: 100%;
        height: 104px;
        background-color: $gray-01;
        border-radius: 12px;
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .folder-icon {
            font-size: 36px;
            color: $gray-04;
        }

        .empty-pane-text {
            font-size: 14px;
            line-height: 18px;
            color: $gray-04;
            margin-top: 16px;
        }
    }
}

.right-panel-pane-date-label {
    font-size: 10px;
    color: $gray;
    line-height: 12px;
    padding: 4px;
    background-color: $gray-01-opaque;
    width: fit-content;
    margin: 8px auto;
    border-radius: 6px;
}

.revert-entrances {
    height: 64px;
    background-color: $red;
    padding: 16px 16px 16px 44px;
    box-sizing: border-box;
    color: $white;
    position: relative;

    &-header {
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &-subheader {
        font-size: 10px;
        margin-top: 4px;
    }

    &-icon {
        position: absolute;
        top: 12px;
        left: 10px;
        font-size: 24px;
    }

    &-button.btn {
        border-color: $white;
        width: 44px;
        padding: 1px 6px;
        border-radius: 4px;
        box-sizing: border-box;
        margin: 0;

        .button-text {
            font-size: 10px;
        }
    }
}
