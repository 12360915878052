@import '~@beewise/theme/build/variables';

.footer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: $gray-07;
    margin-top: auto;

    &-side {
      display: flex;
      align-items: center;
      justify-content: start;
      cursor: pointer;
      visibility: visible;

      &.hidden {
          visibility: hidden;
      }

      &:last-child {
        justify-content: end;
      }
  }

  &-main {
      display: flex;
      align-items: center;
      justify-content: center;
  }
}
