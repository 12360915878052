@import '~@beewise/theme/build/variables';

.frame-panel-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    gap: 8px;
    background: $purple;
    border-radius: 16px;
    flex: 1;

    &-info {
      display: flex;
      gap: 8px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $black;

      svg {
        width: 24px;
        height: 24px;
      }
    }

    &-data {
      font-weight: 600;
      font-size: 16px;
      line-height: 28px;
      color: $black;
    }
  }
