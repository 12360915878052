@import '~@beewise/theme/build/variables';

.yard-marker-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  position: relative;
  transform: translate(-50%, -50%);
  transition: all 0.1s ease-out;

  &:hover {
    z-index: 2;
  }

  .icon-wrapper {
    position: absolute;
    top: -110%;


    .icon svg {
      path:first-of-type {
        fill: $black;
        stroke: $white;
        stroke-width: 2;
        stroke-opacity: .7;
      }

      path:nth-of-type(2n) {
          fill:$white;
          stroke:$black;
          stroke-opacity: 1;
          fill-opacity: 1;
      }
    }

    .yard-marker-bhomes-number {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 10px;
      line-height: 20px;
      font-weight: 500;
      color: $black;
      width: 100%;
      text-align: center;
    }
  }

  .yard-label {
    background: rgba(255, 255, 255, .6);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, .25);
    color: $black;
    border-radius: 52px;
    padding: 2px 12px;
    margin-top: 2px;
    font-size: 12px;
    line-height: 1.5;
    text-align: center;
    display: flex;
    align-items: center;
    backdrop-filter: blur(2px);
    font-family: Poppins;


    &.hovered {
      background-color: $white;
    }

    &:hover, &.selected-yard {
      background-color: $white;

      .yard-label-checkbox {
        visibility: visible;
        margin-right: 8px;
        height: 16px;
        width: 16px;
        transition: all 0.1s ease-out;
      }

      .yard-label-checkbox-unselected {
        visibility: visible;
        height: 16px;
        width: 16px;
        display: block;
      }
    }

    &.clicked-yard {
      background-color: $beewise-yellow;
      .yard-label-checkbox {
        display: none;
      }
    }

    &.selected {
      background-color: $beewise-yellow;
      color: $white;
      .yard-label-checkbox {
        display: block;
      }
    }

    &-checkbox {
      visibility: hidden;
      width: 0;
      transition: all 0.1s ease-out;

      &-unselected {
        visibility: hidden;
        height: 0;
        width: 0;
      }

      &:hover {
        .yard-label-checkbox-unselected {
          visibility: hidden;
          height: 0;
          width: 0;
        }
        .yard-label-checkbox-selected {
          visibility: visible;
          height: 16px;
          width: 16px;
          color: $grey-6;
          display: block;
        }
      }

      .hidden {
        visibility: hidden;
        height: 0;
        width: 0;
      }
    }

    .selected {
      visibility: visible;
      height: 16px;
      width: 16px;
      display: block;

      &:hover {
        background-color: $beewise-yellow;
        color: $white;
      }
    }
  }
}

.yard-tooltip-wrapper.tooltip-content {
    padding: 0!important;
    border-radius: 8px;
}
