@import '~@beewise/theme/build/variables';
@import '~@beewise/theme/build/reusables';
@import 'src/variables';

.beehome {
    background-color: $purple;
    width: 100%;
    box-sizing: border-box;

    &-header {
        display: flex;
        margin-left: 24px;
        margin-top: 24px;
        margin-bottom: 24px;
        align-items: center;

        .back-button {
            font-size: 18px;
            color: $gray-08;
            cursor: pointer;
        }

        &-title {
            font-size: 18px;
            color: $kona-grey;
            font-weight: 600;
            margin-left: 8px;
            cursor: pointer;
        }
    }

    &-content {
        //height: calc(100vh - #{$app-header-height} - #{$navigation-bar-height});
        height: calc(100vh - #{$app-header-height});
        overflow: auto;

        &-v4 {
            height: 100%;

            .hives {
                padding-top: 0;
            }
        }

        .summary {
            width: 1138px;
        }

        .summary-item {
            width: 276px;
        }

        #hives {
            margin: 15px 0;

            &.moving-hives {
                margin-bottom: 100px;
            }
        }
    }

    &-legend {
        margin-top: 15px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 45px;
        box-sizing: border-box;

        .beewise-checkbox {
            margin-right: 30px;
            text-transform: capitalize;
        }
    }
}

@media screen and (max-width: 1320px) {
    .beehome {
        /* TODO: return on left panel remove */
        //width: calc(100vw - #{$right-panel-width});
        width: calc(100vw - #{$left-panel-width} - #{$right-panel-width});
    }
}

@media (max-width: $tablet-max-width) {
    .beehome-content {
        margin: auto;
    }
}
