@import '~@beewise/theme/build/variables';

.syrup {
  &-container {
    max-width: 32px !important;
    min-height: 336px;
    border-radius: 8px;
    width: 32px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    color: $text-primary;
    font-size: 14px;
    position: relative;
    background: linear-gradient(0deg, #EEEFF6, #EEEFF6),
    linear-gradient(270deg, rgba(255, 255, 255, .5) 5.48%, rgba(255, 255, 255, .7) 22.25%, rgba(226, 228, 236, .4) 50.58%, rgba(238, 239, 244, .7) 62.14%, rgba(255, 255, 255, .4) 107.81%);
    box-shadow: 0 0 8px 0 #C8CFEB80 inset;
  }

  &-container:before {
    content: "";
    display: block;
    position: absolute;
    border-radius: 50%;
    width: 26px;
    height: 26px;
    background-color: #C7B65E;
    bottom: var(--height);
    transform: translateY(58%);
    left: -6px;
    clip-path: ellipse(31% 17% at 51% 100%);
  }

  &-container:after {
    content: "";
    display: block;
    position: absolute;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    background-color: #C7B65E;
    border: 1px solid $white;
    bottom: var(--height);
    transform: translateY(180%);
  }

  &-text {
    z-index: 1;
    position: absolute;
    bottom: var(--height);
    font-size: 10px;
    color: $gray-80;
  }

  &-level {
    background: linear-gradient(270deg, #F5E697 0%, #EDD559 100%);
    border-radius: 12px 0 8px 8px;
    width: 32px;
    position: relative;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    box-shadow: 0 -10px 16px 0 #C6B97666 inset;
  }
}

.wave {
  --size: 10px;
  --R: calc(var(--size)*1.28);
  -webkit-mask:
          radial-gradient(var(--R) at 25% calc(1.8*var(--size)),$black 99%,#0000 101%)
          calc(25% - 2*var(--size)) 0/calc(4*var(--size)) 100%,
          radial-gradient(var(--R) at 25% calc(-.8*var(--size)),#0000 99%,$black 101%)
          25% var(--size)/calc(4*var(--size)) 100% repeat-x;
}
