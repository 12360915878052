@import '~@beewise/theme/build/variables';

$widget-height: 80px;
$widgets-gap: 10px;

.beehome-widgets {
  width: 98%;
  margin: 24px auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: $widgets-gap;

  .widget-block {
    box-shadow: 0 4px 17px 0 rgba(0, 0, 0, .05);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
  }

  .hive-assessment-widget {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: $widgets-gap;
    flex: 4;

    .hive-assessment-widget-block {
      @extend .widget-block;
      padding: 24px 12px;
      gap: 14px;
      height: $widget-height;
      box-sizing: border-box;
      flex: 1;
    }

    .hive-assessment-widget-data {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      h2 {
        color: $text-primary;
        font-size: 18px;
        font-weight: 500;
      }
      h3 {
        color: $text-secondary;
        font-size: 13px;
      }
    }
    .hive-assessment-widget-icon {
      font-size: 28px;
      color: $text-secondary;
    }
  }

  .beehome-yard-status-widget {
    @extend .widget-block;
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: $widget-height;
    box-sizing: border-box;
    line-height: 18px;
    flex: 1;

    h4 {
      font-size: 11px;
      font-weight: 700;
      color: $text-primary;
    }
    .beehome-yard-status-widget-text {
      font-size: 11px;
      color: $text-secondary;
    }
  }
}
