@import '~@beewise/theme/build/variables';

$HIVE_PANEL_WIDTH: 450px;

.frame-panel {

  &.right-panel-content {
    overflow: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &-header {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;

    &-top {
      display: flex;
      justify-content: space-between;

      &-left {
        display: flex;
        align-items: center;
        gap: 8px;

        &-icon {
          width: 16px;
          height: 16px;
          cursor: pointer;
        }

        &-hive {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: $black;

          .strong {
            font-weight: 700;
          }
        }
      }
    }

    &-bottom {
      display: flex;
      flex-direction: column;
      gap: 12px;

      &-block {
        display: flex;
        gap: 12px;
      }
    }
  }

  &-main {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    padding-bottom: 24px;
  }
}