@import '~@beewise/theme/build/variables';

.legend {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;

  .legend-octagon {
    width: 8px;
    height: 8px;
    margin-right: 8px;
    border-radius: 50%;
  }

  .not-scanned {
    &.legend-octagon {
      background: radial-gradient($gray-60 0%, $white 90%);
      width: 10px;
      height: 10px;
    }
  }
}
